import feature from '../../../assets/images/godadyfeatures.webp'


const FeaturesData = [
    {
        title: "GoDaddy Features",
        detail: "GoDaddy has all the fundamental features you need in a web host. Its basic plan offers a free domain when you register for a year or longer. GoDaddy restricts its free offering to specific top-level domains including .CLUB, .COM, .NET, .XYZ, .ORG, .CO, and .CN.",
       
       
        detailli:"In fact, that’s all GoDaddy really can offer: the basics. In our research, it earnt a 3.8 out of 5 for features, putting it slapback in the middle of the list. Not the worst, not the best. It’s worth noting that you must also pay the ICANN fee of 18 cents per domain per year for the length of your commitment.",
        subdetail:"On the basic shared hosting plan, GoDaddy offers a free year’s subscription to Microsoft Office 365 business email, typically a $60 annual cost. GoDaddy hosting also offers unmetered bandwidth, which includes unlimited storage and website bandwidth. While unlimited bandwidth is typical for basic web hosting services, unlimited storage is less common. That said, GoDaddy does have some specific limits to its unlimited plans. If you’re using its Linux hosting you are limited to 250,000 inodes — files, directories, and so on — per account. On Windows-based hosting the limit is 500,000 files and folders per account.",
        comment:"“If that sounds complex to you, you’re not likely to be affected. GoDaddy offers a lot of storage that will take a while to fill up for most sites. Also, take care of what hosting you need as well. GoDaddy struggles with dedicated hosting features, with a score of 2.7  out of 5, but comes stronger for shared hosting with a feature score of 4.3 out of 5!  This is an improvement over the last time we reviewed our scores, making GoDaddy one to watch!",
        img:feature,
        lastdetail:"Then there are the different plans. Beyond the basic Economy plan, there are several higher-priced tiers. Deluxe has all the basics of Economy, but it gives you an unlimited number of websites and subdomains. The Ultimate plan increases the processing power of the server plus you get free HTTPS for one year, and beyond that business shared hosting adds more computing resources."
    }]   
            
        

    
     
    

  

export default FeaturesData

