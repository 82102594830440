const FeaturesData = [
    {
        title: "Types of Hosting and Features",
        detail: "When it comes to hosting options, Bluehost presents a diverse range of six types: shared, shared WordPress, WooCommerce, VPS, managed WordPress, and dedicated hosting. Although you can find a comprehensive breakdown of each type in our Bluehost Pricing Review, here's a general overview to help you understand the key differences:",
       
        subli:[ 
            {
                title:"Shared :",
                detail:"The cheapest and easiest general service",
            },
            {
                title:"Shared WordPress :",
                detail:"The cheapest and easiest way to host a WordPress site",
            },
            {
                title:"WooCommerce :",
                detail:"The best way to host an online store with WordPress",
            },
            {
                title:"VPS :",
                detail:"Not as expensive as dedicated hosting, and still good for business sites that need to go beyond the basics",
            },
            {
                title:"Managed WordPress :",
                detail:"Even more features for your hosted WordPress site",
            },
            {
                title:"Dedicated :",
                detail:"The most expensive, but also the most powerful hosting service",
            },
          
        ],

        otherfeatures:[
            {
            title:"Other Bluehost Features :",
            detail: "While Bluehost's seamless integration with WordPress is undoubtedly its most impressive feature, it is by no means the only noteworthy aspect. Though Bluehost scores 3.3 and 3.8 out of 5 for shared and VPS features, respectively, its dedicated and WordPress hosting plans showcase excellent features, earning a solid score of 4.4 out of 5. Here are some other standout offerings from Bluehost that we've compiled below:",
                   
              tabledata:[
                {
                title:"Domain registration",
                detail:"Bluehost offers a free domain to certain users when they sign up for a one-year plan. However, that perk doesn't include the domain registration fee (between $10-15), which you'll still have to pay for."
              },  
            {
                title:"Website builder",
                detail:"There's a drag-and-drop site builder for those who have an idea of what they want to create, but don't have sharp enough HTML and CSS skills.",
            },
            {
                title:"Data transfer",
                detail:"Bluehost offers unlimited data transfer on many of its plans. However, Bluehost also provides CPU protection – more on this below.",
            },
            {
                title:"cPanel",
                detail:"Bluehost uses the 'cPanel' Control Panel, which is a popular site management system. The company also offers free backups so you don't lose any key site files.",
            },
            {
                title:"Storage",
                detail:"Depending on the plan you use, Bluehost offers anything from 10GB up to unlimited storage space for your site files. If all you plan to do is run a WordPress blog with words and images, then the 10GB plan will be fine, and you can always upgrade if you need more space.",
            },
            ]   
            
        },],
    detailli:"Bluehost stands out as a robust option for various hosting types, with remarkable performance across the board. Based on our extensive research, it shines in VPS, dedicated, and WordPress hosting. Notably, Bluehost has catapulted from third place to secure the top position in certain areas, such as achieving an impressive score of 4.6 out of 5 for VPS hosting and an outstanding 4.9 out of 5 for dedicated hosting!",
    subdetail:"The fact that you can get so much in one place is a big draw for customers. When we reached out to our email database for first-hand experiences of Bluehost, Steffi, a consultant, said:",
    comment:"“I used Bluehost, because I could do it all in one place: domain registration, email-admin, hosting, unlimited sub-spaces. The documentation was very good and what I needed at the time.”",
        
        further:[
            {
            title:"Further Information",
            
            furtherinfodetail:[
                {
                    title:"7 Best FTP Hosting Providers :",
                    detail:"Did you know that Bluehost is also the best FTP host? Check out reviews of the top seven to find out more.",
                    },
                    {
                        title:"Best Ecommerce Hosting Providers :",
                        detail:"We tested ecommerce hosting providers and Bluehost came out on top — read our review to see why.",
                    },
                    {
                        title:"DreamHost vs Bluehost :",
                        detail:"See what we think of the two top providers when we compare them side-by-side.",
                    },
                    {
                        title:"Best Drupal Hosting :",
                        detail:"Find out why Bluehost is the best Drupal hosting provider that we’ve tested."
                    }
            ]   
            
        },],

    }];

export default FeaturesData;
