import React from 'react'
import PricingData from './PricingData'
import Accordian from '../../bluehost/accordian/Accordian'

export default function Pricing() {
    return (
        <>
            <div className=" w-full  pt-5 pb-7 mb-[5em]">
                {PricingData.map(data =>
                    <div className="px-5 mx-auto md:w-[80%] pt-5">
                        <div className="">
                            <h3 className='text-[1.4em] md:text-[2.5em] font-bold border-b-[4px] mb-5 border-l-[4px] border-[#2E3B80] md:w-[400px] pl-3 text-[#2E3B80]'>{data.title}</h3>
                        </div>

                        <div className="">
                            <div className="card md:flex md:w-[100%] justify-between items-start">
                                <div className='md:w-[45%]'>
                                    <ul>

                                        <p className='text-[1em] my-4'>{data.PricingDetail.detailone}</p>
                                        <p className='text-[1em] my-4'>{data.PricingDetail.detailtwo}</p>
                                    </ul>
                                </div>
                          

                            </div>
                        </div>

                        <img className='w-full' src={data.img} alt="" />
                        <div className="">
                        <h3 className='font-bold underline pb-2 mt-4'> {data.startingprice.title} </h3>
                        <p className='font-bold'>{data.startingprice.detail}</p>

                      

                        </div>
                       

                        <div className="">
                        {data.startingprice.map(data =>
                        <div className="">
                             <h3 className='font-bold underline py-2'>{data.title}</h3>
                             <p className='font-bold'>{data.detail}</p>    
                             <div className="">
                                {data.prices.map(data =>
                                <ul>
                                    <li className='my-5 text-[1.2em] '><h3 className='text-[1.2em] font-bold'>{data.title}</h3> {data.detail}</li>
                                </ul>
                                )}
                             </div>
                        </div>
                            )}
                        </div>
                        
                       

                                    <div className="">
                                        {data.pricecompare.map(data =>  
                                        <div className="nx-auto">
                                        <h3 className='font-bold underline py-2'>{data.title}</h3>
                                        <p className='font-bold'>{data.detail}</p>

                                        <div className="">
                                        {data.pricetable.map(data => 
                                        
                                        <div className="mt-4">

                                            <table className='mx-auto'>
                                                <tr className='border py-5'>
                                                    <th className='border w-[100%] md:w-[200px] p-4'></th>
                                                    <th className='border w-[100%] md:w-[200px] p-4'>{data.brand[0]}</th>
                                                    <th className='border w-[100%] md:w-[200px] p-4'>{data.brand[1]}</th>
                                                    <th className='border w-[100%] md:w-[200px] p-4'>{data.brand[2]}</th>
                                                    <th className='border w-[100%] md:w-[200px] p-4'>{data.brand[3]}</th>
                                                </tr>
                                                
                                                <tr>
                                            <td className='border w-[100%] md:w-[200px] p-4 block text-center'>{data.company[0]}</td>
                                            <td className='border w-[100%] md:w-[200px] p-4 text-center'>${data.rate[0]}</td>
                                            <td className='border w-[100%] md:w-[200px] p-4 text-center'>${data.rate[1]}</td>
                                            <td className='border w-[100%] md:w-[200px] p-4 text-center'>${data.rate[2]}</td>
                                            <td className='border w-[100%] md:w-[200px] p-4 text-center'>${data.rate[3]}</td>
                                           
                                        </tr>
                                        <tr>
                                            <td className='border w-[100%] md:w-[200px] p-4 block text-center'>{data.company[1]}</td>
                                            <td className='border w-[100%] md:w-[200px] p-4 text-center'>${data.hostgator[0]}</td>
                                            <td className='border w-[100%] md:w-[200px] p-4 text-center'>${data.hostgator[1]}</td>
                                            <td className='border w-[100%] md:w-[200px] p-4 text-center'>${data.hostgator[2]}</td>
                                            <td className='border w-[100%] md:w-[200px] p-4 text-center'>${data.hostgator[3]}</td>
                                        </tr>
                                        <tr>
                                            <td className='border w-[100%] md:w-[200px] p-4 block text-center'>{data.company[2]}</td>
                                            <td className='border w-[100%] md:w-[200px] p-4 text-center'>${data.inmotion[0]}</td>
                                            <td className='border w-[100%] md:w-[200px] p-4 text-center'>${data.inmotion[1]}</td>
                                            <td className='border w-[100%] md:w-[200px] p-4 text-center'>${data.inmotion[2]}</td>
                                            <td className='border w-[100%] md:w-[200px] p-4 text-center'>${data.inmotion[3]}</td>
                                        </tr>
                                        <tr>
                                            <td className='border w-[100%] md:w-[200px] p-4 block text-center'>{data.company[3]}</td>
                                            <td className='border w-[100%] md:w-[200px] p-4 text-center'>${data.setuprfree[0]}</td>
                                            <td className='border w-[100%] md:w-[200px] p-4 text-center'>${data.setuprfree[1]}</td>
                                            <td className='border w-[100%] md:w-[200px] p-4 text-center'>${data.setuprfree[2]}</td>
                                            <td className='border w-[100%] md:w-[200px] p-4 text-center'>${data.setuprfree[3]}</td>
                                        </tr>   
                                        <tr>
                                            <td className='border w-[100%] md:w-[200px] p-4 block text-center'>{data.company[4]}</td>
                                            <td className='border w-[100%] md:w-[200px] p-4 text-center'>${data.totalplan[0]}</td>
                                            <td className='border w-[100%] md:w-[200px] p-4 text-center'>${data.totalplan[1]}</td>
                                            <td className='border w-[100%] md:w-[200px] p-4 text-center'>${data.totalplan[2]}</td>
                                            <td className='border w-[100%] md:w-[200px] p-4 text-center'>${data.totalplan[3]}</td>
                                        </tr>
                                            </table>

                                          
                                        </div>

                                        )}
                                        </div>
                                        
                                        </div>

                                            


                                        )}
                                        <p className='mt-4'>{data.detail}</p>
                                        <h3 className='font-bold underline py-2'>{data.stitle}</h3>

                                     


                                    </div>
                                          
                    </div>

                    
                )}
            </div>
        </>
    )
}
