import React from 'react'
import CompetitorsData from './CompetitorsData'

export default function Competitors() {
  return (
    <>
         <div className=" w-full bg-[#93C5FD] pt-5 pb-7 ">
            {CompetitorsData.map(data => 
            <div className="px-5 mx-auto md:w-[80%] pt-5">
                <h3 className='text-[1.4em] md:text-[2.5em] font-bold  mb-5  md:w-[950px]  text-[#2E3B80]'>{data.title}</h3>
                <p className='text-white'>{data.detail}</p>
            </div>
            )}
        </div>
    </>
  )
}
