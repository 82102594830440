import React from 'react'

export default function Comparison(props) {
  return (
    <>


      <div className="w-[100%] md:w-[1000px]  m-auto  py-[5em]">
        <div className="">
          <h3 className='text-[1.4em] md:text-[2.5em] font-bold text-center'>
            {props.heading}
          </h3>
        </div>

        <div className="text-center">
          <h3 className='text-[1.2em] md:text-[2em] font-extrabold mb-4'>{props.title}</h3>
          <p className='text-[1em] md:text-[1.2em] '>{props.detail}</p>
        </div>
      </div>

    </>
  )
}
