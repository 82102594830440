import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

const Faq = () => {
  const [openIndices, setOpenIndices] = useState([]);
  
  const faqData = [
    {
      question: "What do you mean by webhosting?",
      answer: "Web hosting is like renting space on the internet where you can store all the files and data needed for your website to be accessible to others online. It's a service that allows your website to be visible and functional on the internet.",
    },
    {
      question: "What are the 3 types of web hosting?",
      answer: (
        <ul>
          <li><strong>Shared Hosting:</strong>  Multiple websites sharing resources on one server, like living in an apartment building with shared utilities.</li>
          <li><strong>VPS Hosting:</strong>  Virtual private servers provide dedicated-like resources in a shared environment, akin to having your own condominium within a complex.</li>
          <li><strong>Dedicated Hosting:</strong>  Having an entire server exclusively for your website, similar to owning a standalone house on your own property.</li>
        </ul>
      ),
    },
    {
      question: "Why do I need webhosting?",
      answer: "Web hosting servers are the space where your website can live and be stored while being accessible on the larger web. If you want your website to be live online—in other words, if you want anyone other than you to see it—then a website hosting package is a necessary part of the equation.",
    },
    {
      question: "Why is web hosting used?",
      answer: "Web hosting is used to deliver sites and apps online. In the early days of the Internet, businesses and individuals needed to have their own servers in order to host their sites.",
    },
    {
      question: "What is web hosting for beginners?",
      answer: "Web hosting makes the files that comprise a website (code, images, etc.) available for viewing online. Every website you've ever visited is hosted on a server. The amount of space allocated on a server to a website depends on the type of hosting. The main types of hosting are shared, dedicated, VPS and reseller.",
    },
    {
      question: "What is web hosting vs domain?",
      answer: "The main difference between domain and hosting is that domain is the address, which allows a visitor to easily find your website online, while hosting is where the website files are stored. In order to have a functioning website, you need both – a domain and hosting space.",
    },
    {
      question: "What is a web hosting fee?",
      answer: "Most web hosting companies offer cloud hosting services from $1-200/month. Find a web hosting provider that offers valuable features for each cloud hosting package. Hostinger's cloud hosting plans include free domain registration, an SSL certificate, and a 30-day money-back guarantee.",
    },
    {
      question: "Can we host a website for free?",
      answer: "There are free website hosting services available, but all free plans have limitations. Customer support, storage, bandwidth and perks, such as free domain names, are usually lacking in free plans. Free web hosting can work for personal websites and blogs, developer testing, side gigs and startup sites",
    },
  ];

  const toggleQuestion = (index) => {
    if (openIndices.includes(index)) {
      // If the question is open, close it by removing its index from openIndices
      setOpenIndices(openIndices.filter((i) => i !== index));
    } else {
      // If the question is closed, open it by adding its index to openIndices
      setOpenIndices([...openIndices, index]);
    }
  };

  return (
    <div className="w-full py-10 mb-10">
      <div className="px-5 mx-auto md:w-[80%] pt-5">
        <h2 className="text-3xl md:text-4xl font-bold text-[#2E3B80] mb-6">Frequently Asked Questions</h2>
        <ul className="space-y-6">
          {faqData.map((faq, index) => (
            <li key={index} className="border-t border-gray-300 pt-1">
              <h3
                className="text-xl md:text-lg font-semibold text-[#2E3B80] mb-2 cursor-pointer flex justify-between items-center"
                onClick={() => toggleQuestion(index)}
              >
                {faq.question}
                <FontAwesomeIcon icon={openIndices.includes(index) ? faChevronUp : faChevronDown} />
              </h3>
              {openIndices.includes(index) && <p className="text-gray-600">{faq.answer}</p>}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Faq;
