import logo from '../../../assets/icons/hostinger.webp'
import img from '../../../assets/images/hostgatorstars.webp'

const OverAllData = [
    {
        title:"BEST FOR",
        subtitle:"Best for Discounted Prices",
        logo:logo,
        overall:{
                    title:"OVERALL RATING",
                    detail:"Hostinger is best known for its affordable prices and ecommerce-friendly features, but it does have some drawbacks worth considering. Read on to determine if Hostinger is the right provider for your site.",
                   
        },

        webhosting:{
                    title:"",
                    detail:"Hostinger is not only an affordable alternative to some of the top hosting providers, but it’s also a great choice if you’re looking for an ecommerce hosting solution.",
                    subdetail:"It offers free migration and a wide range of VPS hosting plans, but it does only come tenth in our list of the best hosts, receiving an overall score of 3.6 out of 5. Ultimately, Hostinger’s let down by its lack of storage and absence of dedicated hosting options.",
                    img:img,
        },

        bluehostfor:{
            title:"",
            detail:"Looking for a hosting provider can certainly feel like a daunting task. There are so many options out there, and they all look pretty similar on the surface. But our team at Website Builder Expert has done the deep digging to uncover the strengths and weaknesses of each provider. We’ve spent hours researching and cross-referencing their respective features, and have been helping regular people – not tech experts – get online for 10 years.",
        }

    },

]


export default OverAllData