import React from 'react'
import PricingData from './PricingData'

export default function Pricing() {
  return (
    <>
       <div className="mx-4 my-[5em]">
        {PricingData.map(data =>
        <div className="mx-auto md:w-[80%]">
           <h3 className='text-[1.4em] md:text-[2.5em] font-bold text-center mb-5  text-[#2E3B80]'>{data.title}</h3>
                <p className='text-[1.2em] my-4'>{data.detail}</p>

                <img src={data.img} alt="" className='mx-auto' />
                <img src={data.imgtwo} alt="" className='mx-auto' />
        </div>
        )}
      </div>
    </>
  )
}
