import webrating from '../../../assets/icons/Bluehost_home_page.png';

const OverAllData = [
  {
    subtitle: "Best Overall Hosting Provider",
    webhosting: {
      img: webrating,
    },
    bluehostfor: {
      title: "Who Is Bluehost Best For?",
      detail: "Bluehost sets itself apart as a highly reliable hosting provider, boasting an impressive array of hosting types, robust features, and budget-friendly plans suitable for beginners. Our comprehensive research has unequivocally led us to rate Bluehost as the top hosting provider in the market. Notably, if you're seeking the ideal hosting option for your WordPress site, Bluehost emerges as the undisputed champion in our evaluations, earning an impressive score of 4.6 out of 5. Notably, Bluehost has garnered WordPress's endorsement since 2005, further solidifying its status as the go-to choice for WordPress hosting. Moreover, for those engaged in ecommerce ventures, Bluehost shines as an excellent option for hosting your online store, making it a standout choice for this specific domain.",
    }
  },
];

export default OverAllData;
