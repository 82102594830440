const FeaturesData = [
    {
        title: "Types of Hosting and Features",
        detail: "HostGator offers five different types of hosting: Shared, Managed WordPress, VPS, Dedicated, and Cloud. HostGator knocks it out of the park with a shared plan overall score of 4.7 out of 5, because of its good prices, 45 day money back guarantee, its joint highest performance score, with unlimited storage space and bandwidth. The VPS plans are joint first with Bluehost, and offer the best help and support of all the providers we tested. While the dedicated plan offers unlimited bandwidth, HostGator comes in third place with a dedicated hosting score of 4.4 out of 5.",
       
        subli:[ 
            {
                title:"Web Hosting:",
                detail:"is the easiest and most affordable hosting option, in which you share a server and its resources with other sites. Prices start at $2.75/month.",
            },
            {
                title:"WordPress Hosting :",
                detail:"The cheapest and fast, more secure, & awesomely easy WordPress site",
            },
            {
                title:"eCommerce Hosting :",
                detail:"The best way to host an online store",
            },
            {
                title:"Dedicated Hosting :",
                detail:"The impressive power of AMD EPYC Processors and DDR5 RAM at your fingertips",
            },
            {
                title:"VPS Hosting :",
                detail:"Next gen servers with top-notch performance",
            },
            {
                title:"Reseller Hosting :",
                detail:"Sell our hosting, earn some cash. Create your own hosting business and run it any which way you like.",
            },
          
        ],
    detailli:"HostGator has the best feature score for photography hosting, getting 4.3/5. Comparatively it’s shared hosting feature score was 4/5, coming in 8th place out of 12 providers. Every HostGator plan comes with a free SSL certificate and unlimited bandwidth. You also get a free domain for the first year (after that, you’ll have to pay to renew your domain name). These features aren’t uncommon, but HostGator stands out by also offering unlimited email accounts, sub-domains, and file transfer protocol (FTP) accounts (which allow you to transfer files from a computer to a network). HostGator also includes unlimited storage in its shared hosting plans, which most providers hold out on until you upgrade to more advanced hosting.",
    subdetail:"HostGator gives you the option to use its own drag-and-drop website builder to create your site. The builder offers a large number of templates across 20 categories, including photography, restaurants, and personal portfolios. However, while these templates are customizable, they’re not very easy to navigate – so if you’d rather not commit the time, you don’t have to use HostGator’s own website builder! If you plan to create a website using a CMS system, you’ll be pleased to know that HostGator’s WordPress hosting option comes with convenient one-click installation.",
    comment:"It also offers support for Joomla and Drupal, and the ecommerce platform Magento. The standout feature on HostGator’s basic plan is the free email marketing software it provides through Constant Contact. This feature is incredibly valuable, because it allows you to build an audience through consistent newsletters and automated email campaigns. Just like strong uptime, email marketing is an asset for any site, but it’s particularly helpful for ecommerce sites.",
        
       
    }];

export default FeaturesData;
