import logo from '../../../assets/icons/inmotion.webp'

const VisitLinkData = [
        {
            logo:logo,
            rating:"4.8",
            
            power:{
                title:"",
                detail:""
            },

            starting:{
                title:"",
                detail:""
            },
            
            link:"VISIT SITE"
        }
]


export default VisitLinkData