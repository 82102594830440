import img from '../../../assets/images/featursdream.webp'

const FeaturesData = [
    {
        title:"Types of Hosting and Features",
        detail:"DreamHost offers shared, VPS, dedicated, cloud, and managed WordPress hosting, the strongest of which is shared hosting. It has an overall features score of 4.4 out of 5, but it comes in second overall for shared hosting, joint with Bluehost with a score of 4.5 out of 5. What’s great about DreamHost is that even though it covers a lot of hosting types, it never underperforms for one in particular. Across all our research categories, DreamHost never drops below a 4 out of 5 overall, which is quite impressive. Its worst type of hosting is VPS, no due in small part to a quite poor performance score of 2.5 out of 5 (which we covered earlier). It also has its very own website builder, an online tool that helps non-techy folk create their very own website – but more on that later.",
        img:img,
    },
    {
        title:"Features",
        detail:"The features DreamHost offers are mostly consistent across all its plans, but can sometimes vary. This depends on two things: the type of hosting you have, and the tier of pricing plan you’re on. To make it easy for you, we’ve broken down some of DreamHost’s key features, discussing what they are and what you get."
    },
    {
        title:"Backups and Domain Name",
        detail:"DreamHost provides all its customers with free daily backups, regardless of what plan they’re on. This is a brilliant feature and really gives you peace of mind should something go wrong. If you still need to purchase a domain name, that’s also free for the first year. To qualify for this, you’ll need to register a domain ending in any of the following: .com, .org, .club, .net, or .info."
    },
    {
        title:"SSL Security Certificate",
        detail:"A Secure Sockets Layer (SSL) Certificate helps protect your visitors’ personal information. It’s symbolized by a small padlock icon next to your URL, which tells people that your site is safe to browse. It’s also useful for SEO (Search Engine Optimization), as unsecured sites are usually punished by Google in search results rankings. DreamHost provides a free SSL certificate to every site it hosts."
    },
    {
        title:"Bandwidth",
        detail:"Because DreamHost doesn’t track bandwidth or traffic, every plan comes with an unmetered transfer. This means there is technically no bandwidth limit. Of course, you may run into other issues if you experience a huge spike in traffic, but it’s a great bonus that you don’t have to worry about bandwidth limits."
    },
    {
        title:"RAM",
        detail:"Random Access Memory, or RAM, is how all your site’s information is stored on its server. The larger your site is, the more RAM is required to store and load it. If you opt for a cloud hosting package, you can expect RAM limits between 512MB and 16GB. On a dedicated hosting plan, however, that jumps to between 4GB and 64GB. To give you some context, those RAM limits for dedicated hosting are right up there with the best providers around. The cloud hosting RAM limits, though, are pretty average."
    },
    {
        title:"One-Click Installs and Automated Updates",
        detail:"These features are specific to WordPress websites and act as a real-time-saver. One-click installs allow you to set up your DreamHost account with your WordPress site in exactly that – one click. DreamHost also automatically keeps tabs on any software updates WordPress rolls out, meaning you don’t have to worry about staying up to date. It doesn’t do the same for WordPress plugins, though, so you will have to manage that yourself."
    },
    {
        title:"Further Information:",
        detail:["1. Best VPS Hosting Providers " , " 2. Best Dedicated Hosting Providers " , " 3. Best Cloud Hosting Providers"]
    }
]

export default FeaturesData