
const ProsConsData = [

    {
        title:"PROS and Cons",
        
        pros:
        [
            {
            title:"Great performance –",
            detail:" InMotion offers great storage, bandwidth, Cores and RAM, meaning your website can hold more files and data.",
            },
            {
                title:"Unlimited disk space and free SSL on the basic plan –",
                detail:"It’s almost unheard of for a hosting service to offer unlimited disk space on its most basic plan, but that’s exactly what you get with InMotion.",
            },
            {
                title:"Pretty good support –",
                detail:"InMotion’s support score is second best for shared plans but goes down a little on VPS and dedicated.",
            },
    ],

    cons:
        [
            {
            title:"Quite expensive –",
            detail:"You can sign up to an InMotion shared hosting plan for $3.49/month (paid annually), but this is only on an annual subscription. The cheapest renewal price after that is $9.99/month. You do however get more promotional prices the more time you pay for.",
            },
            {
                title:"No Windows servers –",
                detail:"You’ll find most hosting providers operate using Linux servers. Some also offer Windows hosting, but InMotion isn’t one of them.",
            },
            
    ]

    }
]


export default ProsConsData