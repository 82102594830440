import React  from 'react'
import VisitLinkData from './VisitLinkData'
import {Link } from "react-router-dom/dist";

export default function VisitLink() {
  return (
    <>
      <div >
       {VisitLinkData.map(data =>
        <div className="md:flex justify-between items-center border mt-[5em] mb-[4em] mx-5 p-2 md:p-5 hover:bg-[#93C5FD] transition duration-700 ease-in-out">
            
            <div className="md:border-r  md:px-3 md:h-[100px] md:flex flex-1 items-center justify-center">
                <img src={data.logo} alt="" />
            </div>
            
            <div className="md:border-r  md:px-3 md:h-[100px] md:flex flex-1 items-center justify-center my-5 md:my-0">
              <div className="flex items-center">
                <h4 className='text-[1.2em] font-bold'>
                  {data.rating} 
                </h4>
                <div className="ml-2">
                 <i className={`fas fa-star star-icon ${data.rating >= 0.5 ? 'text-yellow-500' : 'text-gray-400'}`}></i>
                 <i className={`fas fa-star star-icon ${data.rating >= 1.5 ? 'text-yellow-500' : 'text-gray-400'}`}></i>
                 <i className={`fas fa-star star-icon ${data.rating >= 2.5 ? 'text-yellow-500' : 'text-gray-400'}`}></i>
                 <i className={`fas fa-star star-icon ${data.rating >= 3.5 ? 'text-yellow-500' : 'text-gray-400'}`}></i>
                 <i className={`fas fa-star star-icon ${data.rating >= 4.5 ? 'text-yellow-500' : 'text-gray-400'}`}></i>
                </div>
              </div>
            </div>

            <div className="md:border-r  md:px-3 md:h-[100px] md:flex flex-1 items-center justify-center my-5 md:my-0">
              <div className="md:text-center">
              <h4 className='text-[1em] font-bold'>{data.power.title}</h4>
              <h5 className='md:pt-4'>{data.power.detail}</h5>
              </div>
            </div>

            <div className="md:border-r  md:px-3 md:h-[100px] md:flex flex-1 items-center justify-center my-5 md:my-0">
                <div className="md:text-center">
                <h4 className='text-[1em] font-bold'>{data.starting.title}</h4>
                <h5 className='md:pt-4'>{data.starting.detail}</h5>
                </div>
            </div>

            <div className="md:px-3 md:h-[100px] md:flex flex-1 items-center justify-center my-5 md:my-0">
                <Link className='bg-[#196BDE] hover:bg-[#2E3B80] hover:text-white transition duration-700 ease-in-out w-[100%] md:w-[176px] block py-5 rounded text-white font-bold text-[1.2em]  text-center'>
                {data.link} 
                </Link>
            </div>

        </div>
        )}
      </div>
    </>
  )
}
