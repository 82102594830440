

const PricingData = [
    {
        title:"Pricing & Value for Money",
        
        detail:"GoDaddy’s overall value for money score is 3.5 out of 5, but there are a lot of variations in that average. For example, GoDaddy has the lowest value for money score for dedicated hosting, with a 1.9 out of 5. Shared hosting fares little better, with a 2.5 out of 5. But for VPS, GoDaddy actually manages 2nd with a 3.8 out of 5!",
        subdetail:"GoDaddy has frequent sales, where prices for shared hosting with the Economy plan start as low as $6.49 per month when you sign up for 3 years. After that initial period, the price goes up to $8.99 per month. The Deluxe plan has an introductory price of $7.49 per month, and the Maximum is $22.99. That’s quite a bit more expensive than Bluehost or HostGator, where prices start under $5. In fact, value for money is one of the major areas GoDaddy has gone down, with the Economy plan costing only $1.99 before.",
        thirdDetail:"However, that extra cost could be worth it for anyone that wants to use GoDaddy’s user-friendly website builder.",
        fourthDetail:"GoDaddy’s dedicated WordPress hosting for higher-traffic blogs has an introductory price of $8.99 per month ($9.99 after the initial period), and Deluxe and Ultimate plans with starter prices at $11.99 and $18.99 respectively. There’s also the Web Hosting Plus plans, which puts WordPress site on a private VPS site for you. It starts with the Launch plan at $21.99 per month and goes up to the Expand plan starting at $69.99 per month. Virtual private server (VPS) hosting starts a lot cheaper than other providers at $5.99 per month for a VPS with 1GB of memory, 20GB storage, unmetered bandwidth, 2 dedicated IPs, and a free SSL certificate for a year. There are three other VPS plans with prices ranging from $23.99 per month to $83.99 per month at the introductory rate. Dedicated hosting, where you basically rent a server from GoDaddy, starts at $149.99 per month and goes all the way up to $469.99. That might sound expensive (and it is), but they renew at those prices as well. This plan is saved only for the biggest websites, so if you just have a blog or a small store you don’t need to concern yourself with them really. ",

    }
]


export default PricingData