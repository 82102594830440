import logo from '../../../assets/icons/godady.webp'


const OverAllData = [
    {
        title:"BEST FOR",
        subtitle:"Best for Extra Features",
        logo:logo,
        overall:{
                    title:"OVERALL RATING",
                    detail:"GoDaddy is a well-rounded hosting provider, offering four different types of hosting (shared, VPS, Dedicated, and WordPress) and an array of brilliant features. Its pricing plans aren't cheap, but all the extras make GoDaddy a bit special. Alongside hosting, it has a website builder and is the world's largest domain registrar.",
        },

        webhosting:{
                    title:"",
                    detail:"For decades now, GoDaddy has stayed on top as one of the biggest names in web hosting, domain registration, and online marketing. GoDaddy offers a ton of services and aims to be a one-stop shop for all of your Internet needs.",
                    subdetail:"Web hosting and domain name registration doesn’t sound like an exciting business, in fact it sounds downright tame. Even so, GoDaddy has managed to stay in the public eye with its infamous Super Bowl commercials and the recreational habits of its founder.",
                    
        },

        bluehostfor:{
            title:"",
            detail:"But what of its hosting? GoDaddy came in 6th in our web hosting research with a decent overall score of 4 out of 5. In fact, beginners may find a certain advantage to choosing GoDaddy, since you can manage your domain easily with a dedicated domain registrar, as well as get your web hosting needs fulfilled with a capable service. However, GoDaddy isn’t the cheapest company around. In fact, in our value-for-money research, we gave it an overall score of 3.5 out of 5. Certainly not the worst, but not the best either. You do get a lot for your money with its services, however. Higher prices don’t always mean you’re being ripped off, and GoDaddy is a fine example. Its hosting services are as good as anybody’s, and its free add-ons are among the best in the business. It’s a question of finding a plan that fits neatly with your needs. Convenience and peace of mind have their value as well, and that’s where GoDaddy shines.",
        }

    },

]


export default OverAllData