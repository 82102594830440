import logo from '../../../assets/icons/inmotion.webp'
import webrating from '../../../assets/icons/webrating.webp'

const OverAllData = [
    {
        title:"",
        subtitle:"",
        logo:logo,
        overall:{
                    title:"OVERALL RATING",
                    detail:"InMotion is the overall third best web hosting provider around with an overall hosting score of 4.5 out of 5. Known for its excellent features (particularly security features) InMotion came out best for photography in our research, getting a photography score of 4.7 out of 5. While not quite on the same level overall as Bluehost, InMotion still has a lot to offer. And, depending on your needs, InMotion may be the best choice for you. InMotion is fantastic for larger websites, blogs, and ecommerce stores!",
        },

        webhosting:{
                    title:"",
                    img:webrating,
                    detail:"In doing our own research of the very best hosting providers, testing them, and comparing the data, we can say without a doubt which providers are best for what. InMotion came in third place overall, gaining an overall score of 4.5 out of 5. InMotion’s strengths in the types of hosting it offers are VPS, WordPress, and ecommerce hosting, and is the very best for photography hosting.",
                    subdetail:"",
        },

        bluehostfor:{
            title:"Who Is InMotion Best Suited For?",
            detail:"We recommend InMotion for photography sites due to its impresive performance in our photography hosting research. The security features that make it so good for photographers also make InMotion a fantastic choice for medium to large businesses. Consider InMotion if you have a decent size budget, and want to create an ecommerce website or a website that has a lot of data and imagery. InMotion in our research earns an ecommerce score of 4.6 out of 5, placing it second after Bluehost. Notably across InMotion plans you can expect some excellent features, fantastic performance, and pretty great help and support too. Let’s take a closer look.",
        }

    },

]


export default OverAllData