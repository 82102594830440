import React from 'react';
import ProsConsData from './ProsConsData';

export default function ProsCons() {
  return (
    <div className="w-full bg-[#f0f4f8] py-8">
      <div className="container mx-auto px-4 md:px-8">
        {ProsConsData.map((data, index) => (
          <div key={index} className="mb-8">
            <h3 className="text-2xl md:text-3xl font-bold text-[#2E3B80] mb-6 text-center">{data.title}</h3>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <h4 className="text-xl font-semibold text-green-600 mb-3">Pros</h4>
                <ul className="space-y-3">
                  {data.pros.map((item, index) => (
                    <li key={index} className="bg-white p-4 rounded-lg shadow-sm">
                      <h5 className="text-lg font-bold text-[#2E3B80] mb-1">{item.title}</h5>
                      <p className="text-gray-700">{item.detail}</p>
                    </li>
                  ))}
                </ul>
              </div>
              <div>
                <h4 className="text-xl font-semibold text-red-600 mb-3">Cons</h4>
                <ul className="space-y-3">
                  {data.cons.map((item, index) => (
                    <li key={index} className="bg-white p-4 rounded-lg shadow-sm">
                      <h5 className="text-lg font-bold text-[#2E3B80] mb-1">{item.title}</h5>
                      <p className="text-gray-700">{item.detail}</p>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
