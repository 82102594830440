import React from 'react'
import Slider from "react-slick";
import DetailsData from './DetailsData';

const NextArrow = (props) => {
  const {onClick} = props
return(
  <div className="control-btn" onClick={onClick}>
      <button className="next absolute top-[2%] right-[20px] w-[30px] h-[30px] flex items-center justify-center  bg-[#fff] rounded-[50%] z-[5]  text-[1.4em] ">
      <ion-icon name="arrow-forward-outline"></ion-icon>
      </button>
  </div>
)
}

const PrevArrow = (props) => {
  const {onClick} = props
return(
  <div className="control-btn" onClick={onClick}>
      <button className="prev absolute top-[2%] left-[20px] w-[30px] h-[30px] flex items-center justify-center  bg-[#fff] rounded-[50%] z-[5] text-[1.4em] ">
      <ion-icon name="arrow-back-outline"></ion-icon>
      </button>
  </div>
)
}

export default function Detail() {

  
  const settings = {
    dots: true,
    infinite: false,
    arrows:true,
    speed: 1500,
    slidesToShow: 5,
    slidesToScroll: 4,
    initialSlide: 0,
    nextArrow:<NextArrow />,
    prevArrow:<PrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
         
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 300,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  
    
  
  return (
    
    <>
       <Slider {...settings} >
        
      {DetailsData.map(data =>
         <div className="md:px-4 ">
         
         <div className="cardSlide  bg-[#2E3B80] py-5 px-2 text-white rounded-lg mx-1 md:mx-0 hover:bg-[#000] transition duration-700 ease-in-out">
         <div className="">
           <h3 className='text-center font-bold text-[1.5em] uppercase w-full'>{data.package}</h3>
           
           <div className="details md:flex px-2 py-2 md:justify-between mt-5 border-b">
             <h4>Overall rating <span>{data.Overall_rating}</span></h4>
             <div className='px-4'></div>
             <h4>Starting from <span>{data.startingfrom}</span></h4>
           </div>

           <div className="details md:flex px-2 py-2 md:justify-between mt-5 border-b">
             <h4>Uptime <span>{data.uptime}</span></h4>
             <div className='px-4'></div>
             <h4>Free backups <span>{data.freebackups}</span></h4>
           </div>

           <div className="details md:flex px-2 py-2 md:justify-between mt-5 border-b">
             <h4 >Free Domain <span>NO</span></h4>
             <div className='px-4'></div>
             <h4 className=''>Storage Space <span>{data.storagespace}</span></h4>
           </div>

           <div className="details md:flex px-2 py-2 md:justify-between mt-5 border-b">
             <h4>Bandwidth <span>{data.bandwidth}</span></h4>
             <div className='px-4'></div>
             <h4 className='text-[transparent]'>-</h4>
             <h4>RAM <span>{data.ram}</span></h4>
           </div>


         </div>
         </div>
       </div>
      )}

       

       
        
       </Slider>
      
    </>
  )
}
