import React from 'react'
import Banner from '../../home/banner/Banner'
export default function Header() {
  return (
    <>
       <div>
      <h3>
        <Banner title="IONOS" subtitle="Review 2023" detail="Is it Right for You?" />
      </h3>
      </div>
    </>
  )
}
