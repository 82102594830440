
const ProsConsData = [

    {
        title:"PROS and Cons",
        
        pros:
        [
            {
            title:"",
            detail:"DreamHost offers shared hosting plans for as little as $2.95/month. It’s one of the cheapest quality provider we’ve tested.",
            },
            {
                title:"",
                detail:"DreamHost provides dedicated WordPress hosting that comes packed with handy features, like one-click installs and automated WordPress updates.",
            },
            {
                title:"",
                detail:"Has the second best uptime of any provider we've tested with a 99.98%",
            },
    ],

    cons:
        [
            {
            title:"",
            detail:"Unlike many of its competitors, you can’t call DreamHost directly. You can request a call back, or reach out via live chat, by submitting a ticket, or using its online help center.",
            },
            {
                title:"",
                detail:"If you’re a large business who needs its own dedicated server, you’re better off looking at brands like HostGator, Bluehost, or InMotion, which offer far better features.",
            },
            {
                detail:"We certainly wouldn’t recommend DreamHost for cloud hosting. You can’t even set your own resource limits."
            }
            
    ]

    }
]


export default ProsConsData