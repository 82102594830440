import React from 'react'
import FeaturesData from './FeautresData'

export default function Features() {
  return (
    <>
      <div className="mx-4 my-[5em]">
        {FeaturesData.map(data =>
          <div className="mx-auto md:w-[80%] text-center">
          <h3 className='text-[1.4em] md:text-[2.5em] font-bold  mb-5   text-[#2E3B80]'>{data.title}</h3>
          <p className='text-[1em] md:text-[1.2em] text-center'>{data.detail}</p>

          <img src={data.img} alt="" className='mx-auto my-4' />
        </div>
        )}
      </div>
    </>
  )
}
