import React, {useState} from 'react'

export default function Accordian(props) {

const [selected , setSelected] = useState(null)

  const toggle = (i) => {
    if(selected === i){
      return setSelected(null)
    }
    setSelected(i)
  }

  return (
    <>
         <div className="accordian bg-orange-500 md:w-[50%] mx-auto p-5 mt-4">
                    <div className="header flex justify-between" onClick={()=> toggle(props.index)}>
                                  <h3>{props.title}  </h3>
                                  
                                  <span> {selected === props.index ? '-' : '+'} </span>
                          </div>
                          <div className={selected === props.index ? 'h-auto transition duration-700 ease-in-out ':'h-[0px] overflow-hidden '}>
                             <p className='text-[1em] my-4'>{props.detail}</p>
                             <ul>
                              <li>{props.list}</li>
                             </ul>
                             <p className='text-[1em] my-4'>{props.subdetail}</p>
                             <p className='text-[1em] my-4'>{props.detailthree}</p>
                          </div>
            </div>
    </>
  )
}
