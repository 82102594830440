import React from 'react';
import FeaturesData from './FeaturesData';

export default function HostingFeatures() {
  return (
    <div className="w-full bg-[#F3F4F6] py-8">
      <div className="container mx-auto px-4 md:px-8">
        {FeaturesData.map((data, index) => (
          <div key={index} className="mb-8">
            <h3 className="text-2xl md:text-3xl font-bold text-[#2E3B80] mb-6 text-center">{data.title}</h3>
            <p className="text-lg md:text-xl text-gray-700 mb-6 text-center">{data.detail}</p>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
              {data.subli.map((item, index) => (
                <div key={index} className="bg-white p-4 rounded-lg shadow-sm">
                  <h4 className="text-xl font-bold text-[#2E3B80] mb-1">{item.title}</h4>
                  <p className="text-gray-700">{item.detail}</p>
                </div>
              ))}
            </div>

            <p className="text-lg md:text-xl text-gray-700 mb-6">{data.detailli}</p>

            <div className="bg-[#2E3B80] p-5 rounded-lg text-white mb-6">
              {data.otherfeatures.map((feature, index) => (
                <div key={index} className="mb-6">
                  <h4 className="text-xl font-bold mb-4">{feature.title}</h4>
                  <p className="mb-4">{feature.detail}</p>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    {feature.tabledata.map((item, idx) => (
                      <div key={idx} className="bg-white text-gray-900 p-4 rounded-lg shadow-sm">
                        <h5 className="font-bold underline pb-2">{item.title}</h5>
                        <p>{item.detail}</p>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>

            <p className="text-lg md:text-xl text-gray-700 mb-6">{data.subdetail}</p>
            <p className="text-lg md:text-xl text-gray-700 mb-6">{data.comment}</p>

            <div className="bg-[#2E3B80] p-5 rounded-lg text-white mt-6">
              {data.further.map((furtherItem, index) => (
                <div key={index}>
                  <h4 className="text-xl font-bold mb-4">{furtherItem.title}</h4>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    {furtherItem.furtherinfodetail.map((info, idx) => (
                      <div key={idx} className="bg-white text-gray-900 p-4 rounded-lg shadow-sm">
                        <h5 className="font-bold underline pb-2">{info.title}</h5>
                        <p>{info.detail}</p>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
      {/* SEO Keywords */}
      <div className="hidden">
        vps, web hosting, website hosting, domain checker, free website hosting, free web hosting, virtual private server, infinityfree, free hosting sites, hostin, wordpress hosting, free vps, website hosting, free hosting sites.
      </div>
    </div>
  );
}
