import React from 'react'
import { Link } from "react-router-dom/dist";
import Detail from './Detail';

export default function Hosting(props) {
  return (
    <>
      <div className="px-4 pt-8 pb-14 mt-[0em] mb-[2em] bg-[#93C5FD]">
        <div className=" text-center">
          <h3 className='text-[1.2em] md:text-[2em] font-extrabold text-white'>{props.title}</h3>
          <div className="flex justify-center flex-wrap">
            <Link to="/bluehost" className='bg-[#196BDE] hover:bg-[#2E3B80] hover:text-white transition duration-700 ease-in-out w-[100%] md:w-[176px] py-5 rounded text-white font-bold text-[1.2em] mx-1 my-1 '>Bluehost</Link>
            <Link
              to="/hostgator" className='bg-[#196BDE] hover:bg-[#2E3B80] hover:text-white transition duration-700 ease-in-out w-[100%] md:w-[176px] py-5 rounded text-white font-bold text-[1.2em] mx-1 my-1 ' >HostGator</Link>
            <Link
              to="/inmotion" className='bg-[#196BDE] hover:bg-[#2E3B80] hover:text-white transition duration-700 ease-in-out w-[100%] md:w-[176px] py-5 rounded text-white font-bold text-[1.2em] mx-1 my-1 ' >InMotion</Link>
            <Link
              to="/dreamhost" className='bg-[#196BDE] hover:bg-[#2E3B80] hover:text-white transition duration-700 ease-in-out w-[100%] md:w-[176px] py-5 rounded text-white font-bold text-[1.2em] mx-1 my-1 ' >Dreamhost</Link>
            <Link
              to="/godaddy" className='bg-[#196BDE] hover:bg-[#2E3B80] hover:text-white transition duration-700 ease-in-out w-[100%] md:w-[176px] py-5 rounded text-white font-bold text-[1.2em] mx-1 my-1 ' >GoDaddy</Link>
            <Link
              to="/hostinger" className='bg-[#196BDE] hover:bg-[#2E3B80] hover:text-white transition duration-700 ease-in-out w-[100%] md:w-[176px] py-5 rounded text-white font-bold text-[1.2em] mx-1 my-1 ' >Hostinger</Link>
            <Link
              to="/ionos" className='bg-[#196BDE] hover:bg-[#2E3B80] hover:text-white transition duration-700 ease-in-out w-[100%] md:w-[176px] py-5 rounded text-white font-bold text-[1.2em] mx-1 my-1 ' >IONOS
            </Link>



          </div>

          <div className="mx-auto text-justify md:text-center mt-5 pb-4 w-[100%] md:w-[800px]">
            <h3 className='text-[1.2em] md:text-[2em] font-extrabold pt-[1.5em] text-white'>{props.secTitle}</h3>
            <p className='text-[1em] md:text-[1.2em] text-white'>{props.detail}</p>
          </div>
        </div>
        <Detail />
      </div>
    </>
  )
}
