
const CompetitorsData = [
    {
        title:"IONOS vs Its Competitors",
        detail:"Anyone looking for a hosting provider knows there are tons of companies out there, all jostling for position and attention. IONOS has been around long enough to earn a place in the pack – but just where does it sit? In our research, we found IONOS sits in 7th place out of the 13 hosting providers we tested – an improvement since our last round of testing where it ranked ninth overall! Of course, IONOS could still do better when it comes to ease of use and customer service, but it offers a great range of hosting types, as well as good value for money. It’s also the best of the bunch when it comes to site speed and uptime. The fact it provides a lot more than just hosting really makes IONOS stand out from the crowd. When considering its rivals, IONOS could learn a few things from HostGator about customer support, which scored 5 out of 5 overall, in comparison to IONOS’ 1 out of 5. And though IONOS is an excellent choice for dedicated hosting, Bluehost still came out on top with an score of 4.9 out of 5, just ahead of IONOS’ 4.7 out of 5."
    },
    {
        detail:"Ultimately, the decision is yours! IONOS is the best of the bunch when it comes to site speed and uptime, but the number one spot overall lies with the feature-rich and cost-effective Bluehost. It’s a well-established hosting provider, and is even recommended by WordPress itself, so it’s not surprising it scored a mighty 4.8 out of 5 overall in our research. IONOS, to compare, ended up with a fair 4 out of 5."
    },
    {
        title:"Further Information",
        detail:"Web Hosting Comparison Chart: For a side-by-side look at each hosting provider. HostGator Review: Want to find out more about HostGator? Learn why we recommend it so highly. Bluehost Review: Interested in Bluehost? Check out our in-depth review and see if it’s the perfect hosting choice for you. Best WordPress Hosting Provider: Do you have a WordPress site? Read our review and discover your best option today."
    }
]

export default CompetitorsData