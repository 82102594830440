import React from 'react'
import FeaturesData from './FeaturesData'


export default function HostingFeatures() {

  

  return (
    <>
      <div className="w-full bg-[#93C5FD] pt-5 pb-7 my-[5em]">
        {FeaturesData.map((data , i) => (
          <div className="px-5 mx-auto md:w-[80%] pt-5">
            <h3 className='text-[1.4em] md:text-[2.5em] font-bold border-b-[4px] mb-5 border-l-[4px] border-[#2E3B80] md:w-[500px] pl-3 text-[#2E3B80]'>{data.title}</h3>
            <p className='text-[1em] md:text-[1.2em] text-center'>{data.detail}</p>

            
            <p className='text-[1em] md:text-[1.2em] text-center'>{data.detailli}</p>

         
            <p className='text-[1em] md:text-[1.2em] text-center my-4'>{data.subdetail}</p>
            <p className='text-[1em] md:text-[1.2em] text-center'>{data.comment}</p>
          
          <img src={data.img} alt="" className='my-4' />
          <p className='text-[1em] md:text-[1.2em] text-center '>{data.lastdetail}</p>
          </div>

  

        ))}
      </div>
    </>
  )
}
