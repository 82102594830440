import React from 'react'
import { Link } from 'react-router-dom'

export default function Footer() {
  return (
    <>
    
      <div className="bg-[#2E3B80]">
        <div className="footer mx-7 py-[4em]">
          <ul className='md:flex pb-12 border-b'>
            <li className='text-white text-[1em] md:text-[1.2em]'>
              <Link to="/AboutUs">About Us</Link>
            </li>
            <li className='px-3'></li>
            <li className='text-white text-[1em] md:text-[1.2em]'>
              <Link to="/ContactUs">Contact Us</Link>
            </li>
            <li className='px-3'></li>
            <li className='text-white text-[1em] md:text-[1.2em]'>
              <Link to="/PrivacyPolicy">Privacy Policy</Link>
            </li>
            <li className='px-3'></li>
            <li className='text-white text-[1em] md:text-[1.2em]'>
              <Link to="/TermsofUse">Terms of Use</Link>
            </li>
          </ul>

          <div className="md:flex justify-between pt-12">
            <h4 className='text-white text-[1em] md:text-[1.2em]'>© 2023 AfterEncode. All Rights Reserved.</h4>
            <h4 className='text-white text-[1em] md:text-[1.2em]'>
              Street 1601 Medfra St City/Town Anchorage ,  State/Province/Region	Alabama <br />
              Zip/Postal Code	99501 Phone Number	(907) 279-1600 Country	United States
            </h4>
          </div>

        </div>
      </div>
     
    </>
  )
}
