import React from 'react'
import PerformanceData from './PerformanceData'

export default function Performance() {
  return (
    <>
        <div className="my-[5em]">
            {PerformanceData.map(data=> 
                 <div className="px-5 mx-auto md:w-[80%]">
                   <h3 className='text-[1.4em] md:text-[2.5em] font-bold  text-[#2E3B80] text-center'>{data.title}</h3>
                   <p className='text-[1em] text-center py-4'>{data.detail}</p>
                   <img className='mx-auto' src={data.img} alt="" />
                   <p className='text-[0.8em] text-center'>{data.label}</p>
                   <p className='text-[1em] text-center py-4'>{data.subdetail}</p>
                   <h3 className='text-[1.4em] md:text-[2.5em] font-bold  text-[#2E3B80] text-center'>{data.further.title}</h3>
                   <p className='text-[1em] text-center py-4'>{data.further.detail}</p>
                </div>
            )}
        </div>
    </>
  )
}
