

const HostGatorBriefData = [
    {
        title:"HostGator In Brief",
        detail:"HostGator had humble beginnings in a dorm room at Florida Atlantic University in 2002 (which explains the “Gator,” in case you were wondering). Since then, the company has opened offices around the world, and hosts over 8 million domains.",
        
        table:[
            {
            title:"Overall Rank",
            detail:"#2 out of 12 hosting providers"
            },
            {
                title:"Overall Rank",
                detail:"#2 out of 12 hosting providers"
                },
                {
                    title:"Pricing",
                    detail:"Starting at $2.75/month (renews at $6.95/month)"
                    },
                    {
                        title:"Hosting Plans",
                        detail:"Shared, WordPress, VPS, Dedicated, and Cloud"
                        },
                        {
                            title:"Help & Support",
                            detail:"24/7 live chat, phone, and email"
                            },
                            {
                                title:"Features",
                                detail:"SSL certificate, unlimited bandwidth, email marketing software"
                                },
                                {
                                    title:"Uptime",
                                    detail:"99.99% guarantee"
                                    }
    ],
    }
]


export default HostGatorBriefData