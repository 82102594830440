import img from '../../../assets/images/Bluehost_Customer_Satisfaction.png';

const CustomerData = [
    {
        title: "Bluehost Customer Satisfaction",
        detail: "Akash Krishna shared:",
        detailItalic: "Akash Krishna was very courteous, fast, responsive and knew exactly how to solve my issue. Since I am a novice on web building, it is appreciated that dedicated employees are out there to help. My website has already assisted many loved ones find their deceased veteran at the Florida National Cemetery.",
        date: "Date of experience: July 24, 2024",
    },
    {
        detail: "Neil S shared:",
        detailItalic: "I have used Bluehost for a range of services including web hosting, domain registration, and email services. The service has always been excellent and the price can't be beaten. They compare extremely well with their competitors who may supply similar service levels but with much, much higher charges and many annoying add-on options - which are tirelessly promoted. If you are looking for a user-friendly site for web hosting, domain, email, SSL, and all the other standard services I can't recommend them more highly.",
        date: "Date of experience: July 24, 2024",
        img: img,
    },
    {
        limitations: {
            limitationstitle: "Limitations",
            limitationdetail: "Some customers express frustration with the lack of pre-installed features. While Bluehost offers a lot, you need to actively select which features to include on your site, often at an additional cost.",
        },
        contactInfo: {
            contact: "Call 888-401-4678 (International calling fees may apply)",
            address: "WHERE TO FIND US\nBluehost Headquarters\n5335 Gate Pkwy, 2nd Floor,\nJacksonville, FL 32256",
        },
    },
];

export default CustomerData;
