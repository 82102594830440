import img from '../../../assets/images/Bluehost-Pricing.png';

const PricingData = [
  {
    label: "Bluehost Web Hosting Plans",
    plans: [
      {
        name: "BASIC",
        price: "1.99",
        discount: "75% off",
        renewalPrice: "7.99",
        storage: "10GB SSD storage",
        visits: "Supports around 15,000 visits per month"
      },
      {
        name: "PLUS",
        price: "3.99",
        discount: "67% off",
        renewalPrice: "11.99",
        storage: "20GB SSD storage",
        visits: "Can handle approximately 50,000 visits per month"
      },
      {
        name: "CHOICE PLUS",
        price: "3.99",
        discount: "71% off",
        renewalPrice: "13.99",
        storage: "50GB SSD storage",
        visits: "Supports around 200,000 visits per month"
      },
      {
        name: "PRO",
        price: "9.99",
        discount: "52% off",
        renewalPrice: "20.99",
        storage: "100GB SSD storage",
        visits: "Can manage about 400,000 visits per month"
      }
    ],
    subdetail: `
      1 Free Domain for the first year
      Free SSL and CDN enabled
      3 months free Pro Email trial
      Free WP Migration Tool and staging environment (excluding BASIC)
      Yoast SEO (free version)
      Free domain privacy and daily website backup for the first year (CHOICE PLUS and PRO)
    `,
    proExtras: `
      Multi-site management
      Seamless updates
      Performance insights
      Staging site
      Plugin collections
      Client invoicing
    `
  }
];

export default PricingData;
