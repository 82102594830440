import logo from '../../../assets/icons/ionoslogo.webp'

const VisitLinkData = [
        {
            logo:logo,
            rating:"4",
            
            power:{
                title:"POWERING",
                detail:"12 million sites"
            },

            starting:{
                title:"STARTING FROM",
                detail:"$4"
            },
            
            link:"VISIT SITE"
        }
]


export default VisitLinkData