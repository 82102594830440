import img from '../../../assets/images/dreamhost.webp'

const EaseUseData =[
    {
        title:"Ease of Use",
        detail:"Setting up your web hosting with DreamHost is easy. Before getting into that, though, you’re going to need to choose which type of hosting you need. This largely depends on what your website is, and what its requirements are. In most cases, personal sites and startups will be fine using shared hosting. More established businesses may want to look at VPS hosting, while enterprise-level companies will need a dedicated hosting plan. Once you know what type of hosting you need, signing up to a DreamHost plan is a breeze.",
        img:img,
    },
    {
        subdetail:"DreamHost has a simple setup process and an intuitive, custom-built dashboard.",
        detail:"Firstly, you’ll be presented with a list of optional extras. Things like registering a domain name, enhanced security, and email hosting can all be added into your plan at this stage. After you’re happy and your payment has been processed, you’ll enter DreamHost’s dashboard. Unlike most leading hosting providers, DreamHost doesn’t use a cPanel dashboard. Instead, DreamHost has its own custom-made dashboard that’s easy to use and super responsive. If you’re used to working off a cPanel, it will take a little time to get used to. But with plenty of tutorials out there, it’s a pretty gentle learning curve."
    }
]

export default EaseUseData