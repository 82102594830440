

const GoDadyBrief = [
    {
        title:"GoDaddy Hosting In Brief",
        
        detail:"Founded in 1997 as Jomax Technologies, GoDaddy is the largest domain registrar in the world. It’s not clear how many websites it hosts, but the company manages more than 76 million domains. It has 14 facilities around the world, and more than 17.5 million customers worldwide.",
        subdetail:"Before most people around the world even understood what a domain name was or why you’d want one, GoDaddy became a household name. This was largely due to its Super Bowl commercials that began in 2005. In recent years, GoDaddy has given up its Super Bowl ads. Beyond its memorable ads, GoDaddy continued to grow and offer more and more services until it became the full service web shop it is today. Its pricing is not as competitive as some other web hosts; however, you do get some nice perks such as Office 365 email, a website builder, and strong customer support. If all you need is a beginner WordPress site, we’d suggest that pricing should be your primary concern. Not to be too flippant about it, but the WordPress user experience is pretty much the same once you’re in the blog platform’s content management system (CMS)."
    }
]


export default GoDadyBrief