import React from 'react'
import SecurityData from './SecurityData'

export default function Security() {
  return (
    <>
      <div className="main mb-[5em]">
        {SecurityData.map(data =>
        <div className="px-5 mx-auto md:w-[80%] pt-5">
         <h3 className='text-[1.4em] md:text-[2.5em] font-bold  mb-5 text-center   text-[#2E3B80]'>{data.title}</h3>
         <p className='text-center'>{data.detail}</p>
         </div>
        )}
      </div>
    </>
  )
}
