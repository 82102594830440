const DetailsData = [
    {
        id:1,
        package:"Bluehost",
        Overall_rating: "4.8",
        startingfrom:"$2.95",
        uptime:"99.98%",
        freebackups:"NO",
        freedomain:"NO",
        storagespace:"10GB - Unlimited",
        bandwidth:"Unmetered",
        ram:"2GB - 16GB",
    },
       
    {
        id:5,
        package:"GoDaddy",
        Overall_rating: "4.8",
        startingfrom:"$6.99",
        uptime:"99.98%",
        freebackups:"NO",
        freedomain:"NO",
        storagespace:"10GB - Unlimited",
        bandwidth:"Unmetered",
        ram:"700MB - 128GB",
    },

    {
        id:7,
        package:"IONOS",
        Overall_rating: "4.8",
        startingfrom:"$0.50",
        uptime:"99.98%",
        freebackups:"NO",
        freedomain:"NO",
        storagespace:"10GB - Unlimited",
        bandwidth:"Unmetered",
        ram:"700MB - 128GB",
    },

    {
        id:8,
        package:"Hostinger",
        Overall_rating: "4.8",
        startingfrom:"$2.99",
        uptime:"99.98%",
        freebackups:"NO",
        freedomain:"NO",
        storagespace:"10GB - Unlimited",
        bandwidth:"Unmetered",
        ram:"700MB - 128GB",
    },

    {
        id:9,
        package:"SiteGround",
        Overall_rating: "4.8",
        startingfrom:"$2.99",
        uptime:"99.98%",
        freebackups:"NO",
        freedomain:"NO",
        storagespace:"10GB - Unlimited",
        bandwidth:"Unmetered",
        ram:"700MB - 128GB",
    },

]

export default DetailsData;