

const FeaturesData = [
    {
        title:"Types of Hosting & Features",
        detail:"The first thing to explain is that InMotion has five different types of hosting services:"
    },
    {
        detail:"Basic shared business hosting"
    },
    {
        detail:"WordPress hosting"
    },
    {
        detail:"Virtual private server (VPS) hosting"
    },
    {
        detail:"Dedicated servers"
    },
    {
        detail:"Reseller hosting"
    },
    {
        detail:"This is a decent range of plans to choose from – when assessing types of hosting in our research we awarded InMotion 4.3 out of 5 for this offering of different hosting options. Most personal and small business sites will be fine with shared hosting – it’s the cheapest way to host your site, and has the capabilities to handle regular waves of traffic. InMotion’s shared features got a score of 4.1 out of 5, placing it in the middle of all the providers we tested. InMotion’s most basic account is called Shared Business Hosting. This plan includes unlimited disk space and bandwidth, which is especially good. Unlimited bandwidth is quite common, but unlimited disk space for your site is not, at least at the basic level." },
    {
        detail:"Disk space is essentially the amount of storage your website has. You need disk space to host everything on your site — the more pages, content and media you have, the more disk space you’ll need. Bandwidth is the amount of data that can be transmitted at once. Think of bandwidth like a bridge: only a certain amount of cars can cross over at any given time. If the bridge doesn’t have a limit on cars, it can allow as many as possible to cross at once. InMotion also gives you a free SSL certificate, which in turn signals to Google and visitors that your site is safe. Its most basic shared plan also offers other security features like hack and malware protection — keeping your website safe and sound. Like other services, you’ll also get a free parked subdomain, and only on more expensive plans will you get a free domain, unlimited parked and unlimited subdomains."
    },
    {
    detail:"There’s also a free backup manager available to create custom backup generation schedules. Plus some DDoS protection. The free backups are really nice, as not all hosts offer this for free. The DDoS protection is also interesting, though many of the larger web hosts are built to withstand any major denial of service attacks. Like other services, InMotion also has its own drag-and-drop website builder for those who aren’t going down the WordPress route. You will be able to manage your hosting from InMotion’s cPanel. This is a widely used, intuitive dashboard that’s easy to get to grips with."
    },
   
    {
        title:"InMotion’s Advanced Features",
        detail:"If your requirements stretch a little further than shared hosting, there’s plenty of other options. InMotion’s managed VPS hosting, for example, comes with server management and automated security updates. InMotion received a pretty great VPS feature score of 3.9 out of 5, slowly improving in its features as the plan prices increase. Dedicated hosting is another route to go down, but this is usually reserved for large websites or enterprise-level businesses. InMotion’s dedicated hosting feature score is 4.8 out of 5, the best of all the providers tested. It comes with multi-layer security, 20x faster loading times with free SSD, and your own launch assistant to get things off the ground."
    },
    {
        title:"Further Information:",
        detail:"Best Web Hosting Providers – find out who competes with InMotion for the title of best hosting service."
    }
]

export default FeaturesData