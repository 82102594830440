import logo from '../../../assets/icons/dreamhost.webp'


const OverAllData = [
    {
        title:"BEST FOR",
        subtitle:"Great Value for Money",
        logo:logo,
        overall:{
                    title:"OVERALL RATING",
                    detail:"DreamHost is a great hosting provider for those on a budget, especially for those seeking shared hosting. With the second-best uptime we've tested and a brilliant 97-day money-back guarantee, DreamHost is a cheap alternative to big hosting hitters like Bluehost or HostGator.",
        },

        webhosting:{
                    title:"",
                    detail:"Whether you’re in need of shared, VPS, dedicated, or cloud hosting, you can rest assured DreamHost has a plan that’s got you covered. But the real question is, are these plans any good? DreamHost scores an overall rating of 4.4 out of 5 in our hosting rankings, coming in fourth out of 12 providers. Its best hosting type is shared hosting, where it comes joint second with a 4.5 out of 5. If a cheap provider is what you seek, then look no further than DreamHost, since it has some of the best value hosting plans on the market, with discounted prices beginning at $2.95/month and renewing for only $6.99/month! While its shared hosting may be great value, the same can’t be said for its dedicated hosting, which suffers from a dramatic increase in price compared to other packages. There’s also no phone support available at DreamHost, which isn’t great if you’re the type who likes to talk your problems out to a real person.",
                    subdetail:"At Website Builder Expert, we’re committed to helping you get online – and we want to set you up for the best possible chance of success once you’re there. That’s why we independently research a wide range of web hosting providers across different hosting types and industries. Below, you can take a sneak peek at what we made of DreamHost; its overall rating, and how it scored across some key areas:",
                 
        },

        bluehostfor:{
            title:"Further Information:",
            detail:"Best Overall Hosting Provider – discover the 11 top-rated providers on the market , Web Hosting Comparison Chart – take a side-by-side at the best providers  , DreamHost vs Bluehost – see how two of the top providers compare",
        }

    },

]


export default OverAllData