const SecurityData = [
    {
        title:"Security",
        detail:"Looking to keep your website secure? The good news is that every Hostinger plan comes with free unlimited SSL certificates, meaning any sensitive data on your website is carefully protected."
    },
    {
        detail:"Additionally, your website will be safe from harm thanks to Hostinger’s Cloudflare protected nameservers. This works by shielding and diverting potential DDoS attacks or harmful traffic away from your site. And with Hostinger’s Business or Premium plans, your website will also sit behind in-house firewalls to further protect your website and monitor your site’s security."
    },
    {
        detail:"As an extra layer of security, Hostinger’s plans offer daily or weekly backups, so you can rest easy knowing your work or data won’t be lost."
    }
]


export default SecurityData