
const ProsConsData = [

    {
        title:"PROS and Cons",
        
        pros:
        [
            {
            title:"",
            detail:"Excellent template-based website builder",
            },
            {
                title:"",
                detail:"Free Office 365 email with shared hosting",
            },
            
    ],

    cons:
        [
            {
            title:"",
            detail:"Pricing is higher than other major website hosting companies",
            },
            {
                title:"",
                detail:"GoDaddy’s unlimited offerings require a close reading of the fine print",
            },
            
    ]

    }
]


export default ProsConsData