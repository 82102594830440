
const PricingData = [
    {
        title:"IONS Pricing",
        PricingDetail:{
            detailone:"Money makes the world go round. It also makes your head go round and round when you’re trying to compare all of the different prices for hosting plans, set up fees, and email add-ons. ",
            detailtwo:"IONOS is one of the cheapest builders out there, and we found it to be the best value for money, scoring 4.8 out of 5 overall. It performed consistently well across all hosting types, and even came top of the table for price in our VPS hosting research with a score of 3.9 out of 5. IONOS definitely has some prices to turn your head (in a good way)!",
    },
    
      
    startingprice:[{
        title:"Pricing Plans",
        detail:"Here’s a breakdown of the different hosting price plans offered by IONOS. Most of the plans have long-term promotions running, and offer cheaper prices if you commit for a lengthy period of time. However, we’ll be focusing on the pricing for 12-month subscriptions in this article (unless otherwise stated):",

        prices:[
            {
                title:"Shared hosting :",
                detail:"Essential: $4 per month , Business: $1 per month , Expert: $8 per month",
            },
            {
                title:"WordPress hosting :",
                detail:"Start: $2 per month , Grow: $1 per month , Boost: $6 per month",
            },
            {
                title:"SSD Plans:",
                detail:"A8i SSD: $45 per month (for 1st 6 months, then $65/month) , L-16 SSD: $70 per month (for 1st 3 months, then $90/month) , XL-32 SSD: $110 per month (for 1st 3 months, then $130/month) , XL-64 SSD: $140 per month (for 1st 3 months, then $160/month)",
            },
            {
                title:"HDD Plans:",
                detail:"L4i HDD: $47 per month (1 month) , L-16 HDD: $60 per month (for 1st 3 months, then $80/month) , XL-32 HDD: $90 per month (for 1st 3 months, then $110/month) , XL-64 HDD: $120 per month (for 1st 3 months, then $140/month)",
            },
            {
                title:"VPS hosting :",
                detail:"VPS S: $2 per month (1 month) , VPS M: $4 per month (for 1st 6 months, then $7/month) , VPS L: $8 per month (for 1st 6 months, then $15/month) VPS XL: $2 per month (for 1st 6 months, then $25/month) , VPS XXL: $18 per month (for 1st 6 months, then $35/month)",
            },
            {
                detail:"These are extremely attractive deals. With IONOS, you can get started from just $1 per month! Just remember, when the promotion is over, you’ll start paying the regular price, so make sure you know how much you’ll be charged further down the line. For example, the $1/per month deal on the shared hosting Business plan will renew at $10/per month, which is quite the leap!"
            },
            {
                title:"Best Value for Money",
                detail:"For most people, shared hosting is the place to start. In this case, the $1 per month Business plan offers the best value for money for regular websites. Its introductory offer beats out the cheapest Essential plan, and you get unlimited websites, storage, and databases. The Business plan is suitable for the majority of websites. But, if you’re running a serious enterprise and need more than that, the $8 per month Expert plan gives you real bang for your buck too. This plan includes 19GB of RAM, maximum performance, and 100 mailing lists – you even unlock SiteLock malware protection to keep any nasty threats at bay. Alternatively, if you need more power, you can upgrade to a VPS or dedicated hosting plan."
            },
            {
                title:"Hidden Costs",
                detail:"IONOS is upfront with its pricing. As long as you pay attention when you’re choosing your plan, you shouldn’t get caught out by any sneaky costs. At least, in theory. We found several reviews online complaining of unexplained charges. Having scoured IONOS’ Terms and Conditions, we have to assume this is down to error, rather than anything underhand. That said, cancelling your contract is quite tricky – it involves switching off your auto-renewal, and sometimes even a phone call to confirm the termination! There is a $50 set up fee on the dedicated hosting plans. And if you want to use the IONOS MyWebsite website builder or email marketing software, you’ll need to sign up to a plan and pay extra per month. This is the slight downside of IONOS offering so many different services under one roof. The great part is that they’re all easy to integrate and work seamlessly together. The drawback is people expect everything to be included in one price package, and all of this is not as cheap as you first expect. IONOS also offers a 30-day money-back guarantee for all plans, so if you’re not happy with its service, you don’t lose a cent. Overall, IONOS won’t break your bank account. It’s one of the cheapest hosting providers on the market, and its promotions are consistent and offer decent value. There are add-ons which you can purchase, but they’re not forced on you. Just don’t be surprised when renewal time comes around and prices revert back to the regular rates."
            },
            
        ]
        
    }],
    
    pricecompare:[{
                title:"Further Information",
                detail:"Best Cheap Web Hosting Providers: Didn’t find the prices you were looking for? Read our article to find quality services at affordable prices.",
        pricetable:[
           
        ],
      
    }],
  
  

},
]

export default PricingData