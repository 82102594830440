import React from 'react';
import ReliableData from './ReliableData';

export default function Reliable() {
  return (
    <div className="relative py-8">
      <div className="container mx-auto px-4 md:px-8">
        {ReliableData.map((data, index) => (
          <div key={index} className="bg-white p-6 rounded-lg shadow-md mb-8">
            {/* Title and Text */}
            <div className="text-center mb-8">
              <h3 className="text-3xl md:text-4xl font-bold text-[#2E3B80] mb-4">Uptime and Reliability</h3>
              <p className="text-lg text-gray-700 mb-2">{data.label}</p>
              <p className="text-md text-gray-600 mb-4">{data.detail}</p>
              <p className="text-md text-gray-600">{data.subdetail}</p>
            </div>
            
            {/* Image */}
            <div className="relative w-full flex justify-center">
              <img src={data.img} alt="Uptime and Reliability Chart" className="w-full max-w-4xl" />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
