import logo from '../../../assets/icons/bluehost.webp'

const VisitLinkData = [
        {
            logo:logo,
            rating:"4.8",
            
            power:{
                title:"POWERING",
                detail:"2 million sites"
            },

            starting:{
                title:"STARTING FROM",
                detail:"$7.99"
            },
            
            link:"VISIT SITE"
        }
]


export default VisitLinkData