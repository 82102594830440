import img from '../../../assets/images/pricing.webp'
import hiddencost from '../../../assets/images/hiddencost.webp'

const PricingData = [
    {
        title:"Hostinger Pricing",
        PricingDetail:{
            detailone:"So how much does Hostinger cost? Hostinger scores a very low 2.9 out of 5 when it comes to value for money, so let’s take a deeper look at its pricing.",
            detailtwo:"Hostinger’s plans are pretty affordable, especially with its cheap introductory deals. For shared hosting, it scores 3.7 out of 5 for price, and for WordPress hosting, it scores 3.8 out of 5! However, its VPS plans aren’t very cost-effective, scoring 2.9 out of 5 for price. Hostinger’s plans don’t offer much in the way of features to make any higher cost worth it, and it provides little room to scale up, especially without any dedicated hosting options.",
           
        
        },
    
      
    startingprice:[{
        title:"Hostinger Pricing Need-to-Knows :",
        detail:"Hostinger’s pricing can fluctuate depending on your chosen plan, type of hosting, and subscription length! What does remain constant, though, is Hostinger’s 30-day money back guarantee. For fair comparison with other hosting providers, we’ll be looking at Hostinger’s 12-month subscription fees from this point on. Let’s dive into its starting prices for each hosting type:",

        prices:[
            {
                title:"Shared hosting :",
                detail:"$2.99 per month",
            },
            {
                title:"WordPress hosting :",
                detail:"$2.99 per month",
            },
            {
                title:"Cloud hosting :",
                detail:"$9.99 per month",
            },
            {
                title:"VPS hosting :",
                detail:"$3.99 per month",
            },
            {
                detail:"However, the longer you subscribe to one of Hostinger’s plans, the cheaper the plan will be. For example, you can enjoy Hostinger’s cheapest shared hosting plan for just $1.99 per month if you sign up to a 48-month contract. But be wary of Hostinger’s steep renewal costs! Its $9.99 per month (billed annually) cloud hosting plan will renew at $24.99 per month after the first year."
            },
            {
                title:"How Does Hostinger’s Pricing Work?",
                detail:"Given the large array of hosting types and features, every Hostinger plan comes at a different price. Hostinger’s prices are very competitive with the rest of the industry, ranging from $2.99 to $117.99 per month, billed annually. We know what you’re thinking – why such a huge range? Well, Hostinger is no different from other hosting providers in its use of temptingly low introductory prices. The more time you commit to during your first purchase, the more money you save – but only on that first purchase. This is worth keeping in mind at checkout."
            },
            {
                title:"What Subscription Lengths Does Hostinger Offer?",
                detail:"Don’t worry – you don’t have to commit to four years up front! You can choose to pay in advance for either: four years, two years, one year, or one month, and prices will vary accordingly. We recommend purchasing one year in advance, because it’s not too much of a commitment, and you’ll still save some money. This range of subscription options means you have tons of choice over which pricing plan suits you best. Here’s an example – below is a breakdown of the pricing options you get for the Single Web Hosting shared plan alone."
            },
            {
                title:"Single Web Hosting Plan Subscription Details"
            },
        ]
        
    }],
    
    pricecompare:[{
                title:"Bluehost Pricing vs Competitor Pricing",
                detail:"Take a look at the table below to compare our top three hosting providers when it comes to pricing. Again, we’ve focused on 12-month subscriptions to keep the comparison fair.",
        pricetable:[
            {
                title:"Provider",
                brand:["1 month","12 months","24 months","48 months"],
                company:["Regular price/month" , "Sale price/month" , "Renewal price/month", "Setup fee", "Total plan price (using sales price)"],
                rate:["9.99" , "9.99" , "99.99" , "9.99"],
                hostgator:["❌","2.99","2.49","1.99"],
                inmotion:["❌","5.99","4.99","3.99"],
                setuprfree:["4.99","0","0","0"],
                totalplan:["14.98","35.88","59.76","95.52"],
            },
           
            
            
        ],
      
    }],
  
    detail:"We’ve broken down Hostinger’s ranges in the table below, which shows both the introductory price of one year paid in advance, and the renewal price of one year paid in advance.",
    stitle:"Hostinger Pricing Summary ",
    tdetail:"Hostinger also offers a 30-day money back guarantee that applies to all of its plans. This is pretty standard throughout the hosting industry, but still a major plus. Also, if you’re a student, you’re eligible for Hostinger’s 10% student discount. You can access the discount code by verifying your student status through Student Beans for free.",

      plantitl:"Does Hostinger Have Any Hidden Costs?",
      plandetail:"Hostinger may be known for its enticing introductory discounts, but is all it too good to be true? Are there any hidden costs you should look out for? The answer is, not really! But here’s a rundown of the three main extras you should be ready to look out for:" ,
    
      additionalcost:"More Information",
      additionaldetail:"",
      additionalimg:'',

      canceltitle:"",
      canceldetail:"",

      furthertitle:"",

      furtherinfo:[
        {
            list:"Best Cheap Web Hosting Providers :",
            detail:"Check out our guide on the cheapest hosting providers.",
        },
        {
            list:"",
            detail:"Looking for a bargain? Compare over 50 deals in our guide to the cheapest web hosting providers",
        },
        {
            list:"",
            detail:"A great web host doesn’t have to cost the world. Discover the best web hosting providers in our carefully researched list",
        },
        {
            list:"",
            detail:"And, if you’re already sold on Hostinger’s benefits – and want to know more about how much it’s going to cost you – check out our guide to Hostinger pricing for just that",
        }

    ]
},
]

export default PricingData