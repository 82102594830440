
const ProsConsData = [

    {
        title:"What Are the Pros and Cons of 1&1 IONOS?",
        
        pros:
        [
            {
            title:"",
            detail:"Reliable site speed and uptime",
            },
            {
                title:"",
                detail:"One of the cheapest hosting providers on the market",
            },
            {
                title:"",
                detail:"Every customer receives a dedicated support assistant",
            },
            {
                title:"Further Information",
                detail:"Best Web Hosting Providers: Are you after the best of the best? Check out our review where we compare the top providers on the market.",
            },
    ],

    cons:
        [
            {
            title:"",
            detail:"Dated and unintuitive backend control panel",
            },
            {
                title:"",
                detail:"Some hidden/unexpected costs",
            },
            {
                detail:"More costly for Windows users. Help and support often falls short "
            },
            
    ],
    
    },
  
]


export default ProsConsData