

const UpTimeData = [
    {
        title:"Uptime and Performance",
    },
    {
        subtitle:"Performance",
        detail:"DreamHost’s performan Meanwhile, DreamHost’s worst-performing hosting type and worst-performing overall was in VPS hosting with a 2.5 out of 5! "
    },
    {
        subtitle:"UpTime",
        detail:"Uptime is, essentially, it means the amount of time your website is up and working online. But what constitutes good uptime? Aren’t 99.00% and 99.99% both really good? And why does no provider have 100% uptime? These are all valid questions, so allow us to explain. Most hosting services offer 99.9-something percent uptime. For that reason alone, anything less than that is seen as poor. No provider can physically have 100% uptime, simply due to the nature of the internet."
    },
    {
        lastdetail:"DreamHost guarantees uptimes of 99.98% for the sites it hosts. That means, on average, your website will be online and in good working order for all but four and a half hours each year. That roughly equates to five minutes each week. In our research, this is very good! DreamHost actually comes second in our overall rankings across all hosting types with 4.5 out of 5 for uptime. And if that didn’t sound good enough, DreamHost actually maintains a 100% uptime guarantee in its service contract, meaning any downtime logged by you will result in: DreamHost credit equal to the Customer’s current hosting cost for 1 (one) day of service for each 1 (one) hour (or fraction thereof) of service interruption, up to a maximum of 10% of Customer’s next pre-paid hosting renewal fee. Pretty neat, huh?" 
    }
]

export default UpTimeData