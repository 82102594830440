import logo from '../../../assets/icons/godady.webp'

const VisitData = [
        {
            logo:logo,
            rating:"4",
            
            power:{
                title:"POWERING",
                detail:"40.7 million sites"
            },

            starting:{
                title:"STARTING FROM",
                detail:"$6.49"
            },
            
            link:"VISIT SITE"
        }
]


export default VisitData