import React from 'react'
import HostGatorBriefData from './HostGatorBriefData'

export default function HostGatorBrief() {
  return (
    <>
      <div className="my-[5em]">
        {HostGatorBriefData.map(data =>            
         <div className="px-5 mx-auto md:w-[80%]">
         
         <div className="">

         <h3 className='text-[1.4em] md:text-[2.5em] font-bold  text-[#2E3B80] text-center'>{data.title}</h3>
         <p className='text-[0.8em] text-center'>{data.label}</p>
         <p className='text-[1em] text-center py-4'>{data.detail}</p>
         <p className='text-[1em] text-center'>{data.subdetail}</p>
         </div>
         <div className="">
         
           <ul>
           {data.table.map(data=>
            <li className='my-5 text-[1.2em] md:flex items-center'><h3 className='text-[1.2em] font-bold md:mr-4'>{data.title}</h3> {data.detail}</li>
            )}
          </ul>
         
         </div>
      </div>

      
        
        )}
      </div>
    </>
  )
}
