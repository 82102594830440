
import img from '../../../assets/images/ionos.webp'

const PerformanceData = [
    {
        title:"Reliability and Performance",
        detail:"You don’t want a car that’s constantly breaking down – especially if you have to use it every day to get to and from work. The same goes for your hosting provider. You need a reliable service that keeps your website running smoothly. This is where uptime and downtime come in. Uptime shows how much of the time a website is live and running, and downtime is how often sites go “down” and are inaccessible to visitors. The first thing to say is, there’s no such thing as 100% uptime. Updates and fixes mean there will always be instances when downtime is unavoidable. But you want your provider’s uptime to be as close as possible to that perfect score. IONOS by 1&1 has great uptime scores. On its website, it claims to have a guaranteed uptime of 99.9%, and when we tested it ourselves, IONOS aced it, scoring 5 out of 5 for uptime.",
        img:img,
        label:"IONOS offers speed plus security for its users, combining multiple data centers with backups and firewall protection.",
        subdetail:"So it’s got the stability, but what about the speed? In today’s world, website speed is massively important – especially on mobile. If your site takes longer than five seconds to load on mobile, 74% of US mobile users will simply abandon the page!. Visitors are impatient, and page load times can make or break your website. There’s just no point having amazing content if nobody hangs around long enough to see it. IONOS’ performance varies depending on the type of hosting you choose. For best performance, choose its WordPress hosting plans – IONOS scores an impressive 4.9 out of 5 for performance, making it the fastest web host out of all of the ones we tested. IONOS has 10 data centers across five countries – you’re usually given the one located nearest to you, unless you’re on a plan where you can choose your data center. Performance will also vary depending on which of the 10 data centers is being used. Ultimately, IONOS can’t be beat when it comes to performance – your website will load quickly and run without a hitch with IONOS. Its uptime and site speed are lightyears ahead of other hosting providers, including the top dogs Bluehost and HostGator.",

        further:{
            title:"Further Information",
            detail:"Best Web Hosting for Small Business: Reliability and performance are especially important for businesses. Read our review to see a breakdown of the top small business hosts."
        }

    }
]

export default PerformanceData