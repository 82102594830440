import React from 'react';
import PricingData from './PricingData';

export default function Pricing() {
  return (
    <div className="w-full py-8">
      <div className="container mx-auto px-4 md:px-8">
        {/* Introduction */}
        <div className="text-center mb-12">
          <h1 className="text-3xl md:text-4xl font-bold text-[#2E3B80] mb-4">Bluehost Web Hosting Plans</h1>
          <p className="text-lg md:text-xl text-gray-700">
            Discover the best web hosting plans for your needs. Bluehost offers a variety of plans to ensure that you have the right resources for your website, whether you're just getting started or need advanced performance.
          </p>
        </div>

        {/* Plan Details */}
        {PricingData.map((data, index) => (
          <section key={index} className="bg-white p-6 rounded-lg shadow-lg mb-12">
            <h2 className="text-2xl md:text-3xl font-semibold text-[#2E3B80] mb-6 text-center">{data.label}</h2>
            
            {/* Plan cards */}
            <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-4">
              {data.plans.map((plan, i) => (
                <div key={i} className="border border-gray-300 rounded-lg p-6 bg-gray-50 shadow-sm">
                  <h3 className="text-xl font-bold text-blue-700 mb-2">{plan.name}</h3>
                  <p className="text-lg font-semibold text-blue-800 mb-2">
                    ${plan.price} <span className="text-sm font-normal text-gray-500">/month</span>
                  </p>
                  <p className="text-sm text-gray-500 line-through mb-2">
                    ${plan.renewalPrice} <span className="text-red-500">({plan.discount})</span>
                  </p>
                  <ul className="list-disc pl-4 text-gray-700">
                    <li>{plan.storage}</li>
                    <li>{plan.visits}</li>
                  </ul>
                </div>
              ))}
            </div>

            {/* Common Features */}
            <div className="text-gray-700 mt-8">
              <h3 className="text-xl font-semibold text-[#2E3B80] mb-4">Common Features:</h3>
              <ul className="list-disc pl-5">
                {data.subdetail
                  .split('\n')
                  .map(line => line.trim())
                  .filter(line => line) // Remove empty lines
                  .map((line, i) => (
                    <li key={i}>{line.replace(/^\s*-\s*/, '')}</li> // Remove leading dash and spaces
                  ))}
              </ul>
            </div>

            {/* PRO Plan Extras */}
            <div className="text-gray-700 mt-8">
              <h4 className="text-lg font-semibold text-[#2E3B80] mb-4">PRO Plan Extras:</h4>
              <ul className="list-disc pl-5">
                {data.proExtras
                  .split('\n')
                  .map(line => line.trim())
                  .filter(line => line) // Remove empty lines
                  .map((line, i) => (
                    <li key={i}>{line.replace(/^\s*-\s*/, '')}</li> // Remove leading dash and spaces
                  ))}
              </ul>
            </div>

            {/* Image */}
            {data.img && (
              <div className="relative w-full flex justify-center mt-8">
                <img src={data.img} alt={data.label} className="w-full max-w-3xl rounded-md shadow-sm" />
              </div>
            )}
          </section>
        ))}
      </div>
    </div>
  );
}
