import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

const Faq = () => {
  const [openIndices, setOpenIndices] = useState([]);
  
  const faqData = [
    {
      question: "What is HostGator used for?",
      answer: "This is the most affordable way of hosting a website for small businesses, especially if it is their first time launching their page online. It allows them to make their online presence without too much hassle on server configuration.",
    },
    {
      question: "Does HostGator have 24/7 support?",
      answer: "HostGator agents are available 24/7/365 to answer your questions and assist with our products and services. You can contact us via phone or chat , whichever method is most convenient for you! HostGator and Gator Builder are two separate entities with different levels of support.",
    },
    {
      question: "Does HostGator use cPanel?",
      answer: "Once you have ordered your hosting package, you will have two ways to log in and access your cPanel features. Continue reading for more information about accessing cPanel.",
    },
  ];

  const toggleQuestion = (index) => {
    if (openIndices.includes(index)) {
      // If the question is open, close it by removing its index from openIndices
      setOpenIndices(openIndices.filter((i) => i !== index));
    } else {
      // If the question is closed, open it by adding its index to openIndices
      setOpenIndices([...openIndices, index]);
    }
  };

  return (
    <div className="w-full py-10 mb-10">
      <div className="px-5 mx-auto md:w-[80%] pt-5">
        <h2 className="text-3xl md:text-4xl font-bold text-[#2E3B80] mb-6">Frequently Asked Questions</h2>
        <ul className="space-y-6">
          {faqData.map((faq, index) => (
            <li key={index} className="border-t border-gray-300 pt-1">
              <h3
                className="text-xl md:text-lg font-semibold text-[#2E3B80] mb-2 cursor-pointer flex justify-between items-center"
                onClick={() => toggleQuestion(index)}
              >
                {faq.question}
                <FontAwesomeIcon icon={openIndices.includes(index) ? faChevronUp : faChevronDown} />
              </h3>
              {openIndices.includes(index) && <p className="text-gray-600">{faq.answer}</p>}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Faq;
