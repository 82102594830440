import React from 'react'
import Banner from '../../home/banner/Banner'
export default function Header() {
  return (
    <>
       <div>
      <h3>
        <Banner title="Bluehost" subtitle="Review" detail="Is It the Best Host for Your Site? | 2023" />
      </h3>
      </div>
    </>
  )
}
