import img from '../../../assets/images/Bluehost_uptime_reliable.png';

const ReliableData = [
  {
    img: img,
    label: "Bluehost has an impressive advertised uptime, but it scores poorly in our uptime testing.",
    detail: "With an impressive uptime guarantee of 99.98%, Bluehost demonstrates its commitment to reliability. This uptime monitoring ensures better uptime for your website, making it a reliable choice for website uptime monitoring and server uptime. While no web host can achieve 100% uptime, this figure equates to approximately one hour and 45 minutes of downtime per year, which is a commendable performance.",
    subdetail: "Despite its impressive performance in other hosting areas, Bluehost falls short when it comes to uptime performance. In our site speed and uptime testing, it scored only 3 out of 5. Other hosting providers like HostGator and IONOS, which use tools like Uptime Robot for uptime monitoring, received scores over 4, making them more reliable choices in this aspect."
  }
];

export default ReliableData;
