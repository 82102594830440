import logo from '../../../assets/icons/hostgator.webp'

const VisitData = [
        {
            logo:logo,
            rating:"4.7",
            
            power:{
                title:"POWERING",
                detail:"8 million sites"
            },

            starting:{
                title:"STARTING FROM",
                detail:""
            },
            
            link:"VISIT SITE"
        }
]


export default VisitData