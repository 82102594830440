import React from 'react';
import FeaturesData from './FeaturesData';

export default function HostingFeatures() {
  return (
    <div className="w-full py-8">
      <div className="container mx-auto px-4 md:px-8">
        {FeaturesData.map((data, index) => (
          <div key={index} className="mb-8">
            <h3 className="text-2xl md:text-3xl font-bold text-[#2E3B80] mb-6 text-center">{data.title}</h3>
            <p className="text-lg md:text-xl text-gray-700 mb-6 text-center">{data.detail}</p>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
              {data.subli.map((item, index) => (
                <div key={index} className="bg-white p-4 rounded-lg shadow-sm">
                  <h4 className="text-xl font-bold text-[#2E3B80] mb-1">{item.title}</h4>
                  <p className="text-gray-700">{item.detail}</p>
                </div>
              ))}
            </div>

            <p className="text-sm md:text-base text-gray-700 mb-6 text-center">{data.detailli}</p>
            <p className="text-sm md:text-base text-gray-700 mb-6 text-center">{data.subdetail}</p>
            <p className="text-sm md:text-base text-gray-700 mb-6 text-center">{data.comment}</p>
          </div>
        ))}
      </div>
      {/* SEO Keywords */}
      <div className="hidden">
        vps, web hosting, website hosting, domain checker, free website hosting, free web hosting, virtual private server, infinityfree, free hosting sites, hostin, wordpress hosting, free vps, website hosting, free hosting sites.
      </div>
    </div>
  );
}
