import img from '../../../assets/images/inmotion.webp'

const ReliableData = [
    {
        title:"Reliability & Performance",
        detail:"No matter what website large or small, reliability and performance are key to keeping you visible to online visitors. InMotion receives the best performance score for its dedicated hosting with an impressive 4.5 out of 5. In our research of other types of hosting, InMotion comes third place for performance in its VPS, WordPress, and ecommerce hosting plans — meaning your website will perform fairly optimally. But performance encompasses a lot of different areas of running your website, when testing we usually look closely at:"
    },
    {
        detail:"Performance Score"
    },
    {
        detail:"Server Response Time"
    },
    {
        detail:"Storage Space"
    },
    {
        detail:"Bandwidth"
    },
    {
        detail:"Cores (VPS, Dedicated)"
    },
    {
        detail:"Threads (Dedicated)"
    },
    {
        detail:"RAM (VPS, Dedicated)"
    },
    {
    detail:"While not the speediest host in the West, InMotion does offer unlimited bandwidth on its shared plan and 2TB on its cheapest VPS plan. On InMotion’s dedicated plans you can expect a range of 4 to a whopping 24 Cores, and 4GB-16GB of RAM."
    },
    {
        img:img,
    },
    {
        detail:"If you’ve read our reviews, then you know one of the first things you need to determine is what kind of uptime a provider offers. This is key since you want your website to be going offline as little as possible. Uptime refers to the amount of time your website is up and running online for users to browse. Most hosting providers tend to have an uptime of between 98% and 99.99%, with only two providers we’ve tested reaching that 100% uptime score. Overall, InMotion scores a respectable 3 out of 5 for uptime in our ratings. To give context, IONOS was the only host in our ratings to score 5 out of 5, while other popular brands such as Bluehost and A2 Hosting also score 3 out of 5 for uptime. GoDaddy saw the worst uptime rating with 2 out of 5."
    },
    {
        detail:"InMotion’s uptime across shared and VPS plans is 99.98%, based on our research. That’s a great amount of uptime, equal to less than three hours of downtime per year. Not bad at all. The uptime does decrease to 99.90% on dedicated and WordPress plans though, something to keep in mind. InMotion has two U.S-based data centers, located on either coast, which are home to thousands of servers. Though InMotion’s server speeds are pretty great globally, they obviously have a quicker response time if you’re based in the U.S. The only areas where InMotion’s server speeds fall below Google’s benchmark are in Asia and Australia. If that’s where you’re from, you may want to look at other providers."
    }
]

export default ReliableData