import React from 'react';
import SecurityData from './SecurityData';

const keywords = [
  "bluehost security", "sitelock security bluehost", "bluehost sitelock", 
  "bluehost sitelock pricing", "bluehost sitelock security essential", 
  "website security", "website security check", "scan website for vulnerabilities", 
  "scan website for malware", "website security scan", "website penetration testing", 
  "google safe browsing"
];

export default function Security() {
  return (
    <>
      <div className="bg-[#F3F4F6] pt-10 pb-12">
        <div className="container mx-auto px-6">
          {/* Introduction */}
          <div className="text-center mb-8">
            <h1 className="text-3xl font-bold text-blue-800 mb-4">Website Security Protection</h1>
            <p className="text-lg text-gray-700">
            Sitelock, our official website security partner, helps HostGator customers protect their websites from cyber threats. Block the bad guys and be a security superhero! Sitelock provides automated malware removal, protects your brand's reputation, and defends your site from malicious attacks.
            </p>
            <p className="text-lg text-gray-700 mt-4">
            Protect your site and your customers from bad guys with <strong>SiteLock Security</strong>.
            </p>
          </div>
          
          {/* SiteLock Overview */}
          <section className="bg-white p-6 rounded-lg shadow-lg mb-8">
            <h2 className="text-2xl font-semibold text-blue-800 mb-4">What Does SiteLock Offer?</h2>
            <p className="text-gray-700">
              SiteLock offers website security protection for small businesses. It safeguards your website from various online threats like malware, SQL injection, and cross-site scripting. With daily security scanning, SiteLock identifies vulnerabilities and secures your site against potential threats. The <strong>SiteLock Trust Seal</strong> enhances customer confidence and has been proven to boost sales and conversions significantly.
            </p>
          </section>
          
          {/* Benefits and Tips */}
          <section className="bg-white p-6 rounded-lg shadow-lg mb-8">
            <h2 className="text-2xl font-semibold text-blue-800 mb-4">Benefits of Website Security for Small Businesses</h2>
            <p className="text-gray-700">
              Over 70% of online customers look for a Security Seal before providing data to a website. SiteLock's security shield has been shown to increase conversion by 15% in A/B tests, making it a valuable investment for any business looking to protect its website and build trust with its customers.
            </p>
            <h3 className="text-xl font-semibold text-blue-800 mt-6 mb-4">12 Tips to Boost Website Security Protection for Small Businesses</h3>
            <ul className="list-disc pl-5 text-gray-700">
              <li>Regular Security Scans</li>
              <li>User Permissions Management</li>
              <li>File Upload Restrictions</li>
              <li>Error Handling</li>
              <li>Content Security Policy (CSP)</li>
              <li>Disable Directory Listing</li>
              <li>Website Hardening</li>
              <li>Secure Hosting</li>
              <li>Email Security</li>
              <li>Educate Customers</li>
              <li>Monitor Third-Party Integrations</li>
              <li>Response Plan for Security Incidents</li>
            </ul>
          </section>
          
          {/* Summary */}
          <section className="bg-white p-6 rounded-lg shadow-lg">
            <h2 className="text-2xl font-semibold text-blue-800 mb-4">Summary</h2>
            <p className="text-gray-700">
              SiteLock is a comprehensive website security solution for small businesses, protecting against online threats such as malware, SQL injection, and cross-site scripting. The service offers daily security scanning to identify vulnerabilities and secure your website. The SiteLock Trust Seal enhances customer confidence and can significantly boost sales and conversions. For additional support, SiteLock provides on-demand expert services.
            </p>
          </section>
        </div>
      </div>
    </>
  );
}
