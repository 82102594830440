import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import logo from '../../../assets/icons/logo.png';

export default function Navbar() {
  const [isNavbarOpen, setIsNavbarOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  
  // Toggle function for the navbar
  const toggleNavbar = () => {
    setIsNavbarOpen(!isNavbarOpen);
  };

  // Close function for the navbar
  const closeNavbar = () => {
    setIsNavbarOpen(false);
  };

  // Effect to change navbar style on scroll
  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // Check if the environment is development or use a custom condition
  const isDev = process.env.NODE_ENV === 'development'; // Only show link in development mode

  return (
    <nav
      className={`${isScrolled ? 'bg-[#2E3B80]' : 'md:bg-[#2E3B80]'} bg-[#2E3B80] py-2 md:py-5 px-4 md:p-5 md:px-12 text-white w-full md:flex md:items-center md:justify-between z-10 border-b border-solid border-white`}
    >
      <div className="flex justify-between items-center">
        <span className="text-2xl font-[Poppins] cursor-pointer">
          <Link to="/">
            <img style={{ width: "220px" }} src={logo} alt="Logo" />
          </Link>
        </span>
        <span
          className="text-3xl cursor-pointer md:hidden block"
          onClick={toggleNavbar}
        >
          <ion-icon name={isNavbarOpen ? 'close' : 'menu'}></ion-icon>
        </span>
      </div>
      <ul
        className={`md:flex md:items-center md:z-auto md:static ${
          isNavbarOpen ? 'block' : 'hidden'
        } md:w-auto md:py-0 py-4 md:pl-0 pl-1 absolute w-full left-0 opacity-100 md:opacity-100 top-[-400px] ${
          isNavbarOpen ? 'top-[52px] opacity-100 duration-500 bg-[#2E3B80]' : 'top-[490px]'
        }`}
      >
        <li className="mx-4 my-6 md:my-0">
          <Link
            to="/"
            className="hover:text-black duration-500 block"
            onClick={closeNavbar}
          >
            Web Hosting
          </Link>
        </li>
        <li className="mx-4 my-6 md:my-0">
          <Link
            to="/bluehost"
            className="hover:text-black duration-500 block"
            onClick={closeNavbar}
          >
            Best Hosting
          </Link>
        </li>
        <li className="mx-4 my-6 md:my-0">
          <Link
            to="/PhotoGallery"
            className="hover:text-black duration-500 block"
            onClick={closeNavbar}
          >
            Gallery
          </Link>
        </li>
        {/* Conditionally render the Upload link */}
        <li className="mx-4 my-6 md:my-0">
          <Link
            to="/AboutUs"
            className="hover:text-black duration-500 block"
            onClick={closeNavbar}
          >
            About Us
          </Link>
        </li>
      </ul>
    </nav>
  );
}
