
const ProsConsData = [

    {
        title:"PROS and Cons",
        
        pros:
        [
            {
            title:"WordPress plans :",
            detail:"a strong onboarding experience on most WordPress plans ensures great ease of use",
            },
            {
                title:"Affordable hosting plans :",
                detail:" starting from $2.99, billed annually, with extremely cheap discount prices for new users",
            },
            {
                title:"Great for ecommerce hosting :",
                detail:"affordable plans and helpful ecommerce plugins for WooCommerce and PrestaShop",
            },
    ],

    cons:
        [
            {
            title:"No dedicated plan :",
            detail:"Hostinger doesn’t offer any dedicated hosting plans, meaning it’ll be a struggle to scale up beyond a VPS plan",
            },
            {
                title:"No phone support :",
                detail:"you can’t reach out to Hostinger via phone, and instead have to use its 24/7 live chat",
            },
            {
                title:"Poor value for money :",
                detail:"despite cheap introductory deals, you’ll be paying for basic features and not much else",
            },
            
    ]

    }
]


export default ProsConsData