import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

const Faq = () => {
  const [openIndices, setOpenIndices] = useState([]);
  
  const faqData = [
    {
      question: "Is Bluehost free or paid?",
      answer: "Bluehost domain pricing depends on the type of hosting you're buying. If you have a shared, WordPress, or WooCommerce plan, you'll get a free domain for the first year. After that, you'll pay the renewal fee each year, which is listed in the image below.",
    },
    {
      question: "Why is Bluehost so cheap?",
      answer: "Compared side-by-side with Bluehost, DreamHost's VPS packages are about one-third of the price of Bluehost. Why is Bluehost so cheap? Bluehost is so cheap because they provide shared web hosting services. This means that multiple websites share resources on the same server—keeping costs low for everyone.",
    },
    {
      question: "Should I get WordPress or Bluehost?",
      answer: "WordPress.com is a good starting point for simple blogs and personal websites, but Bluehost is faster and more versatile. Choosing between them is a matter of your specific needs, but keep in mind that only one of these hosts will give you full creative freedom over your site.",
    },
    {
      question: "Bluehost pricing?",
      answer: "BlueHost pricing can range from $5.95 per month for its Basic Shared Hosting plan, running to over $200 per month for its top tier Dedicated Hosting plan.",
    },
  ];

  const toggleQuestion = (index) => {
    if (openIndices.includes(index)) {
      // If the question is open, close it by removing its index from openIndices
      setOpenIndices(openIndices.filter((i) => i !== index));
    } else {
      // If the question is closed, open it by adding its index to openIndices
      setOpenIndices([...openIndices, index]);
    }
  };

  return (
    <div className="w-full py-10 mb-10">
      <div className="px-5 mx-auto md:w-[80%] pt-5">
        <h2 className="text-3xl md:text-4xl font-bold text-[#2E3B80] mb-6">Frequently Asked Questions</h2>
        <ul className="space-y-6">
          {faqData.map((faq, index) => (
            <li key={index} className="border-t border-gray-300 pt-1">
              <h3
                className="text-xl md:text-lg font-semibold text-[#2E3B80] mb-2 cursor-pointer flex justify-between items-center"
                onClick={() => toggleQuestion(index)}
              >
                {faq.question}
                <FontAwesomeIcon icon={openIndices.includes(index) ? faChevronUp : faChevronDown} />
              </h3>
              {openIndices.includes(index) && <p className="text-gray-600">{faq.answer}</p>}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Faq;
