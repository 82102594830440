import React from 'react';
import OverAllData from './OverAllData';

export default function OverAll() {
  return (
    <div className="w-full py-8">
      <div className="container mx-auto px-4 md:px-8">
        {OverAllData.map((data, index) => (
          <div key={index} className="mb-8">
            <h3 className="text-2xl md:text-3xl font-bold text-[#2E3B80] mb-6 text-center">{data.subtitle}</h3>
            <div className="flex justify-center mb-6">
              <img src={data.webhosting.img} alt="Web Hosting Rating" className="w-full h-auto rounded-lg shadow-sm" />
            </div>
            <div className="mt-6">
              <h4 className="text-xl font-semibold text-red-600 mb-3"></h4>
              <div className="bg-white p-4 rounded-lg shadow-sm">
                <h5 className="text-lg font-bold text-[#2E3B80] mb-1">{data.bluehostfor.title}</h5>
                <p className="text-gray-700">{data.bluehostfor.detail}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
