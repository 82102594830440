import img from './../../../assets/images/dreahostprice.webp'

const PricingData = [
    {
        title:"Pricing",
        detail:"DreamHost’s value for money across its plans is really good! DreamHost comes third in our overall rankings for value for money, with an overall value score of 4.2 out of 5. Plus, it actually comes first for the cheapest form of web hosting – shared hosting – with a value score of 4.4 out of 5!",
        img:img,
        subdetail:"DreamHost has 26 plans in total, stretched across five different types of hosting.",
    },
    {
        subtitle:"Which DreamHost Plan is the best value?",
        subdetail:'Just because something is cheap doesn’t mean it’s the best value for money. We’ve researched each plan and its offerings against countless others to find you the best bang for your buck. When it comes to good value, DreamHost’s shared hosting plans don’t get much better! With two plans at discount for the great price of $2.95 per month, plus their monster money-back guarantee of 97 days, DreamHost certainly earns its brilliant shared hosting value for money score of 4.4 out of 5 in our research. DreamHost’s managed WordPress plans come in at a perfectly middle of the road 3.2 out of 5 in our research. While they offer some great features, the price of $80 per month for the higher plans is stretching it. VPS hosting fairs a little better with a value score of 3.5 out of 5, coming in joint third place for value along with InMotion. A price decrease from $13.75 down to $10 makes this plan much more appealing to the eyes. Finally, dedicated hosting brings up the rear with 2.5 out of 5 for value for money. The expensive starting price of $149 per month is a little offputting, especially for newcomers.'
    },
    {
        subtitle:"DreamHost pricing vs Competitor pricing",
        subdetail:"We’ve assembled some other top providers os you can see how DreamHost shapes up. We’ve kept them at annual prices to be fair as well:"
    },
   
]


export default PricingData