import React from 'react'
import { Link } from 'react-router-dom'
import VisitCardData from './VisitCardData'


export default function VisitCard() {
    return (
        <>
            <div className="my-[3em] mx-7 ">

               

                {VisitCardData.map(data =>
                   
                   <div className="md:w-[70%] mx-auto" key={data.id}>
                     
                     <div className="">
                                <h3 className='text-[1.2em] md:text-[2em] font-extrabold text-[#2E3B80] pt-12'> <span>{data.number}</span> {data.title}</h3>

                                {data.titlelist && (
                                    <ul>
                                        {data.titlelist.map((subItem, index) => (
                                            <li  key={index} className='text-[1em] md:text-[1.2em] leading-[4empx] pt-2 pb-2'>{subItem}</li>
                                        ))}
                                    </ul>
                                )}
                                <h3 className='text-[1.2em] md:text-[2em] font-extrabold capitalize text-[#2E3B80]'> {data.titleTwo}</h3>
                                <p className='text-[1em] md:text-[1.2em] '> {data.detail} </p>
                                <p className='text-[1em] md:text-[1.2em] mt-3'> {data.detailTwo} </p>
                                <p className='text-[1em] md:text-[1.2em] mt-3'> {data.detailThree} </p>

                                <div className="card my-3 border border-[#93C5FD] px-5 rounded pt-5 pb-10 transition duration-700 ease-in-out">
                                    <div className="card-header block md:flex items-center justify-between border-b pb-5">
                                    <div >
                                            <img src={data.image} alt="Image" className="mx-auto sm:mx-0"/> 
                                            </div>
                                        <div className='mb-3 md:mb-0'>
                                            <h3 className='text-[1.2em] md:text-[2em] font-extrabold text-[#2E3B80]'>  {data.title}</h3>
                                            <span className='text-[1.2em] '>{data.subtitle}</span>
                                        </div>
                                        <div className="mb-3 md:mb-0 rounded-full mr-12">
                                            <span className='text-[1em] md:text-[1.2em] font-bold text-[#93C5FD] mr-2'>{data.rank}</span>
                                            <i className="fas fa-star text-[#27C6A1]"></i>
                                            <i className="fas fa-star text-[#27C6A1]"></i>
                                            <i className="fas fa-star text-[#27C6A1]"></i>
                                            <i className="fas fa-star text-[#27C6A1]"></i>
                                            <i className="fas fa-star text-[#27C6A1]"></i>
                                        </div>


                                            
                                    </div>

                                    <div className="cardmiddle md:flex justify-between ite pt-6">
                                        {data.mainDataOne && (
                                            <div className="">
                                                
                                                <ul>
                                                    
                                                {data.mainDataOne.map((subItem, index) => (
                                                    <li key={index} className='text-[1em] md:text-[1.2em] leading-[1.5em] py-1'>
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="#27C6A1" viewBox="0 0 24 24" strokeWidth={1.5} stroke="#27C6A1" className="w-6 h-6 inline-block align-middle mr-2">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z" />
                                                        </svg>
                                                        {subItem}
                                                    </li>
                                                    ))}


                                                </ul>

                                            </div>
                                        )}
                                        <div className="mr-14 mt-10">
                                            <h3>{data.paid}</h3>
                                        </div>
                                    </div>

                                    <div className=" border-t mt-5 pt-5">

                                        <div className="md:flex justify-between">
                                            <div className="">
                                                <h3 className='text-[1.2em] md:text-[1.4em] font-bold text-[#93C5FD] '>PROS</h3>
                                                {data.mainDataTwo && (
                                                    <div className="">
                                                        <ul>
                                                        {data.mainDataTwo.map((subItem, index) => (
                                                    <li key={index} className='text-[1em] md:text-[1.2em] py-1'>
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 inline-block align-middle mr-2">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                        </svg>
                                                        {subItem}
                                                    </li>
                                                    ))}


                                                        </ul>

                                                    </div>

                                                )}
                                            </div>

                                            <div className=""> 
                                                <h3 className='text-[1.2em] md:text-[1.4em] font-bold text-[#93C5FD] '>CONS</h3>
                                                {data.mainDataThree && (
                                                    <div className="">
                                                        <ul>
                                                            
                                                            
                                                        {data.mainDataThree.map((subItem, index) => (
                                                        <li key={index} className='text-[1em] md:text-[1.2em] py-1'>
                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 inline-block align-middle mr-2">
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                            </svg>
                                                            {subItem}
                                                        </li>
                                                        ))}

                                                        </ul>

                                                    </div>

                                                )}
                                            </div>

                                        </div>
                                    </div>


                                    <Link target="_blank" to={data.link} className='text-[1.2em] text-center font-bold bg-[#0f6bac] text-white px-[4em] py-2 block w-[100%] md:w-[300px] ml-auto mt-5 rounded hover:bg-white hover:text-black transition duration-700 ease-in-out '>
                                        Visit Site
                                    </Link>
                                </div>
                                </div>      
                            </div>
                 
                 
                )}
            </div>

        </>
    )
}

function getBorderClass(rank) {
    switch (rank) {
      case 'A':
        return 'border-circ-a'; // Define this class in your CSS/SCSS
      case 'B':
        return 'border-circ-b';
      // Add more cases as needed for different ranks
      default:
        return ''; // No border class by default
    }
  }
  
