import logo from '../../../assets/icons/ionoslogo.webp'


const OverAllData = [
    {
        title:"BEST FOR",
        subtitle:"Site Speed & Uptime",
        logo:logo,
        overall:{
                    title:"OVERALL RATING",
                    detail:"IONOS is the best hosting provider for site speed and uptime. Its hosting packages come with daily site backups, anti-spam protection, and lots of other useful features. Some of its offerings are stronger than others, though. Read on to find out if it’s the right provider for your needs. Beyond its standout performance, IONOS is also a strong option for dedicated and VPS hosting. In total, IONOS offers four different types of website hosting. Shared , VPS , Dedicated , WordPress"},

        webhosting:{
                    title:"",
                    detail:"IONOS might not beat the top hosting providers in the market, but it is the best choice for site speed and uptime. Every website owner should be at ease knowing their hosting provider is reliable and stable, and in this department, IONOS has your back.",
                    subdetail:"To rank and compare IONOS fairly, our in-house research team tested the top hosting providers to pull together an accurate list of recommendations. We analyzed price, performance, features, help and support, and market analysis. Of the 13 hosting providers we tested, IONOS came seventh, scoring 4 out of 5 overall, despite sitting in the middle of the pack.",
                   
        },

        bluehostfor:{
            title:"",
            detail:"We recommend this hosting provider for small white-collar businesses, largely because of its all-around packages, extremely cheap pricing, and focus on speed and reliability. But, is IONOS the right fit for you?",
        }

    },

]


export default OverAllData