

const ReliableData = [
    {
        title:"Reliability & Performances",
        
        detail:"GoDaddy says it promises 99.99 percent uptime, which amounts to less than a day of downtime per year. Now, whether GoDaddy actually performs at that level is another question. A recent test by Down.com, a website that runs uptime tests on web hosts says GoDaddy has an average uptime of 99.97 percent. That equals a little less than three days of downtime per year, which is still excellent.",
        subdetail:"A 100 percent uptime simply isn’t possible due to inevitable technical problems that can arise. But close to 100 percent, as GoDaddy offers, is what you should realistically expect. In our research, we only gave GoDaddy a 2 out of 5 overall for uptime. But in testing the individual hosting prowess we found that GoDaddy’s best performance score was 4.1 out of 5 for dedicated hosting, while its lowest performance score was 3 out of 5 for blog hosting."
    }
]


export default ReliableData