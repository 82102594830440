import webrating from '../../../assets/icons/hostgator-review.png';

const OverAllData = [
  {
    subtitle: "Best Overall Hosting Provider",
    webhosting: {
      img: webrating,
    },
    hostgatorfor: {
      title: "Who Is HostGator Best For?",
      detail: "HostGator stands out as a leading hosting provider due to its exceptional customer support, performance, and robust shared plan features. Discover if HostGator is the ideal choice for your site by reading further.",
    }
  },
];

export default OverAllData;
