import React from 'react'
import EaseUseData from './EaseIseData'

export default function EaseUse() {
  return (
    <>
      <div className="mx-4 my-[5em]">
        {EaseUseData.map(data =>
            <div className="mx-auto md:w-[80%] text-center">
               <h3 className='text-[1.4em] md:text-[2.5em] font-bold  mb-5   text-[#2E3B80]'>{data.title}</h3>
               <p className='text-[1em] md:text-[1.2em] text-center'>{data.detail}</p>
                <img className='mx-auto my-2' src={data.img} alt="" />
                <p className='text-[1em] md:text-[1.2em] text-center'>{data.subdetail}</p>
            </div>
        )}
      </div>
    </>
  )
}
