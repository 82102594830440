const ProsConsData = [
    {
      title: "Pros and Cons of Hostgator",
      pros: [
        {
          title: "Uptime:",
          detail: "Impressive 99.99% uptime guarantee.",
        },
        {
          title: "Best for:",
          detail: "Leading in shared hosting plans, especially ideal for small businesses and blogs.",
        },
        {
          title: "Features:",
          detail: "Offers extensive features, including free email marketing software.",
        },
        {
          title: "Support:",
          detail: "24/7 phone and live chat, plus a rich, searchable knowledge base.",
        },
      ],
      cons: [
        {
          detail: "Outdated control panel and site design, leading to a less smooth user experience.",
        },
        {
          detail: "Slightly pricier compared to other top hosts.",
        },
      ],
    },
  ];
  
  export default ProsConsData;
  