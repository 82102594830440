import React from 'react'
import Banner from '../../home/banner/Banner'
export default function Header() {
  return (
    <>
       <div className=''>
      <h3>
        <Banner title="GoDady" subtitle="Review" detail="GoDaddy Hosting Review 2023 | Website Builder Expert" />
      </h3>
      </div>
    </>
  )
}
