import React from 'react'
import ProsConsData from './ProsConsData'

export default function ProsCons() {
  return (
    <>
      <div className=" w-full bg-[#93C5FD] pt-5 pb-7 ">
        {ProsConsData.map(data =>
          <div className="px-5 mx-auto md:w-[80%] pt-5">
            <div className="">
            <h3 className='text-[1.4em] md:text-[2.5em] font-bold border-b-[4px] mb-5 border-l-[4px] border-[#2E3B80] md:w-[950px] pl-3 text-[#2E3B80]'>{data.title}</h3>
            </div>

            <div className="">
                <div className="card md:flex md:w-[100%] justify-between items-start">
                    <div className='md:w-[45%]'>
                      <ul>
                       {data.pros.map(data=>
                        <li className='my-5 text-[1.2em] text-white'><h3 className='text-[1.2em] font-bold'>{data.title}</h3> {data.detail}</li>
                        )}
                      </ul>
                    </div>

                    <div className='md:w-[45%]'>
                      <ul>
                       {data.cons.map(data=>
                        <li className='my-5 text-[1.2em] text-white'><h3 className='text-[1.2em] font-bold'>{data.title}</h3> {data.detail}</li>
                        )}
                      </ul>
                    </div>
                </div>
            </div>

        </div>
        )}
      </div>
    </>
  )
}
