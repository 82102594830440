import React from 'react'
import UpTimeData from './UpTimeData'

export default function UpTime() {
  return (
    <>
      <div className=" w-full bg-[#93C5FD] pt-5 pb-7 mb-[5em]">
        {UpTimeData.map(data =>
        <div className="px-5 mx-auto md:w-[80%] pt-5">
        <div className="">
        <h3 className='text-[1.4em] md:text-[2.5em] font-bold  mb-5  text-[#2E3B80] text-center'>{data.title}</h3>
        </div>
        <h3 className='text-[1.4em] md:text-[2em] font-bold  mb-5  text-[#2E3B80]'>{data.subtitle}</h3>
        <p className='text-[1em] md:text-[1.2em] '>{data.detail}</p>
       
         
          <p className='text-[1em] md:text-[1.2em] '>{data.lastdetail}</p>
        </div>
          
        )}
        <div className="">
            <table className="mx-auto">
              <tr>
                <th className='p-5 border md:w-[280px]'>UpTime</th>
                <th className='p-5 border md:w-[280px]'>Annual Downtime</th>
                <th className='p-5 border md:w-[280px]'>Weekly Downtime</th>
              </tr>
              <tr>
                <td className='p-5 border md:w-[280px] text-center'>99.9$</td>
                <td className='p-5 border md:w-[280px] text-center'>98.75 hours</td>
                <td className='p-5 border md:w-[280px] text-center'>10.1 minutes</td>
              </tr>
              <tr>
                <td className='p-5 border md:w-[280px] text-center'>99.5%</td>
                <td className='p-5 border md:w-[280px] text-center'>4.38 hours</td>
                <td className='p-5 border md:w-[280px] text-center'>5.04 minutes</td>
              </tr>
              <tr>
                <td className='p-5 border md:w-[280px] text-center'>99.9%</td>
                <td className='p-5 border md:w-[280px] text-center'>52.6 minutes</td>
                <td className='p-5 border md:w-[280px] text-center'>1.01 minutes</td>
              </tr>
            </table>
          </div>
      </div>
    </>
  )
}
