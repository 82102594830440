import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import Footer from "./components/common/Footer/Footer";
import Navbar from "./components/common/Navbar/Navbar";
import Home from "../src/pages/home/Home";
import { Routes, Route } from "react-router-dom";
import 'slick-carousel/slick/slick.css';
import '../src/App.css';
import './ModalStyles.css';  // Import the new CSS file
import Bluehost from "./pages/bluehost/Bluehost";
import GoDaddy from "./pages/godady/GoDaddy";
import Hostgator from "./pages/hostgator/Hostgator";
import Hostinger from "./pages/hostinger/Hostinger";
import Ionos from "./pages/ionos/Ionos";
import Dreamhost from "./pages/dreamhost/Dreamhost";
import InMotion from "./pages/inmotion/InMotion";
import ReactGA from "react-ga";
import AboutUs from "./pages/aboutus/AboutUs";
import ContactUs from "./pages/contactus/ContactUs";
import PrivacyPolicy from "./pages/privacypolicy/PrivacyPolicy";
import TermsofUse from "./pages/termsofuse/TermsofUse";
import Modal from 'react-modal';
import PhotoGallery from "./components/PhotoGallery/PhotoGallery";
import './App.css';

ReactGA.initialize('UA-159178461-4');

Modal.setAppElement('#root');

function App() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const affiliateLink = 'https://bluehost.sjv.io/1r0B2R';

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsModalOpen(true);
    }, 2000); // Change from 5000 to 3000

    return () => clearTimeout(timer);
  }, []);

  const handleCloseModal = () => {
    // Open the affiliate link in a new tab
    window.open(affiliateLink, '_blank');
    
    // Close the modal without redirecting the user
    setIsModalOpen(false);
  };

  return (
    <>
      <Helmet>
        <title>AfterEncode</title>
        <meta name="description" content="A modern and clean photo gallery layout with a Pinterest-like design." />
      </Helmet>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/bluehost" element={<Bluehost />} />
        <Route path="/godaddy" element={<GoDaddy />} />
        <Route path="/hostgator" element={<Hostgator />} />
        <Route path="/hostinger" element={<Hostinger />} />
        <Route path="/ionos" element={<Ionos />} />
        <Route path="/dreamhost" element={<Dreamhost />} />
        <Route path="/inmotion" element={<InMotion />} />
        <Route path="/AboutUs" element={<AboutUs />} />
        <Route path="/ContactUs" element={<ContactUs />} />
        <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
        <Route path="/TermsofUse" element={<TermsofUse />} />
        <Route path="/photogallery" element={<PhotoGallery />} />
      </Routes>
      <Footer />
      <Modal
        isOpen={isModalOpen}
        onRequestClose={handleCloseModal}
        contentLabel="Affiliate Link"
        className="modal-content"
        overlayClassName="modal-overlay"
        shouldCloseOnOverlayClick={true}
      >
        <h2>Up to 75% off Web Hosting</h2>
        <p>Secure. Speedy. The Way Your Website Should Be. Please <a href={affiliateLink} target="_blank" rel="noopener noreferrer">click here</a> to visit.</p>
        <button onClick={handleCloseModal}>Close</button>
      </Modal>
    </>
  );
}

export default App;
