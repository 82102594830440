import React from 'react'
import PricingData from './PricingData'
import Accordian from '../../bluehost/accordian/Accordian'

export default function Pricing() {
    return (
        <>
            <div className=" w-full bg-[#93C5FD] pt-5 pb-7 mb-[5em]">
                {PricingData.map(data =>
                    <div className="px-5 mx-auto md:w-[80%] pt-5">
                        <div className="">
                            <h3 className='text-[1.4em] md:text-[2.5em] font-bold border-b-[4px] mb-5 border-l-[4px] border-[#2E3B80] md:w-[400px] pl-3 text-[#2E3B80]'>{data.title}</h3>
                        </div>

                        <div className="">
                            <div className="card md:flex md:w-[100%] justify-between items-start">
                                <div className='md:w-[45%]'>
                                    <ul>

                                        <p className='text-[1em] my-4'>{data.PricingDetail.detailone}</p>
                                        <p className='text-[1em] my-4'>{data.PricingDetail.detailtwo}</p>
                                    </ul>
                                </div>
                          

                            </div>
                        </div>

                        <img className='w-full' src={data.img} alt="" />
                        <div className="">
                        <h3 className='font-bold underline pb-2 mt-4'> {data.startingprice.title} </h3>
                        <p className='font-bold'>{data.startingprice.detail}</p>

                      

                        </div>
                       

                        <div className="">
                        {data.startingprice.map(data =>
                        <div className="">
                             <h3 className='font-bold underline py-2'>{data.title}</h3>
                             <p className='font-bold'>{data.detail}</p>    
                             <div className="">
                                {data.prices.map(data =>
                                <ul>
                                    <li className='my-5 text-[1.2em] text-white'><h3 className='text-[1.2em] font-bold'>{data.title}</h3> {data.detail}</li>
                                </ul>
                                )}
                             </div>
                        </div>
                            )}
                        </div>
                        
                       

                                    <div className="">
                                        {data.pricecompare.map(data =>  
                                        <div className="nx-auto">
                                        <h3 className='font-bold underline py-2'>{data.title}</h3>
                                        <p className='font-bold'>{data.detail}</p>

                                        <div className="">
                                        {data.pricetable.map(data => 
                                        
                                        <div className="mt-4">

                                            <table className='mx-auto'>
                                                <tr className='border py-5'>
                                                    <th className='border w-[100%] md:w-[200px] p-4'></th>
                                                    <th className='border w-[100%] md:w-[200px] p-4'>{data.brand[0]}</th>
                                                    <th className='border w-[100%] md:w-[200px] p-4'>{data.brand[1]}</th>
                                                    <th className='border w-[100%] md:w-[200px] p-4'>{data.brand[2]}</th>
                                                    <th className='border w-[100%] md:w-[200px] p-4'>{data.brand[3]}</th>
                                                </tr>
                                                
                                                <tr>
                                            <td className='border w-[100%] md:w-[200px] p-4 block text-center'>{data.company[0]}</td>
                                            <td className='border w-[100%] md:w-[200px] p-4 text-center'>${data.rate[0]}</td>
                                            <td className='border w-[100%] md:w-[200px] p-4 text-center'>${data.rate[1]}</td>
                                            <td className='border w-[100%] md:w-[200px] p-4 text-center'>${data.rate[2]}</td>
                                            <td className='border w-[100%] md:w-[200px] p-4 text-center'>${data.rate[3]}</td>
                                           
                                        </tr>
                                        <tr>
                                            <td className='border w-[100%] md:w-[200px] p-4 block text-center'>{data.company[1]}</td>
                                            <td className='border w-[100%] md:w-[200px] p-4 text-center'>${data.hostgator[0]}</td>
                                            <td className='border w-[100%] md:w-[200px] p-4 text-center'>${data.hostgator[1]}</td>
                                            <td className='border w-[100%] md:w-[200px] p-4 text-center'>${data.hostgator[2]}</td>
                                            <td className='border w-[100%] md:w-[200px] p-4 text-center'>${data.hostgator[3]}</td>
                                        </tr>
                                        <tr>
                                            <td className='border w-[100%] md:w-[200px] p-4 block text-center'>{data.company[2]}</td>
                                            <td className='border w-[100%] md:w-[200px] p-4 text-center'>${data.inmotion[0]}</td>
                                            <td className='border w-[100%] md:w-[200px] p-4 text-center'>${data.inmotion[1]}</td>
                                            <td className='border w-[100%] md:w-[200px] p-4 text-center'>${data.inmotion[2]}</td>
                                            <td className='border w-[100%] md:w-[200px] p-4 text-center'>${data.inmotion[3]}</td>
                                        </tr>
                                        <tr>
                                            <td className='border w-[100%] md:w-[200px] p-4 block text-center'>{data.company[3]}</td>
                                            <td className='border w-[100%] md:w-[200px] p-4 text-center'>${data.setuprfree[0]}</td>
                                            <td className='border w-[100%] md:w-[200px] p-4 text-center'>${data.setuprfree[1]}</td>
                                            <td className='border w-[100%] md:w-[200px] p-4 text-center'>${data.setuprfree[2]}</td>
                                            <td className='border w-[100%] md:w-[200px] p-4 text-center'>${data.setuprfree[3]}</td>
                                        </tr>   
                                        <tr>
                                            <td className='border w-[100%] md:w-[200px] p-4 block text-center'>{data.company[4]}</td>
                                            <td className='border w-[100%] md:w-[200px] p-4 text-center'>${data.totalplan[0]}</td>
                                            <td className='border w-[100%] md:w-[200px] p-4 text-center'>${data.totalplan[1]}</td>
                                            <td className='border w-[100%] md:w-[200px] p-4 text-center'>${data.totalplan[2]}</td>
                                            <td className='border w-[100%] md:w-[200px] p-4 text-center'>${data.totalplan[3]}</td>
                                        </tr>
                                            </table>

                                          
                                        </div>

                                        )}
                                        </div>
                                        
                                        </div>

                                            


                                        )}
                                        <p className='mt-4'>{data.detail}</p>
                                        <h3 className='font-bold underline py-2'>{data.stitle}</h3>

                                        <table className='mx-auto'>
                                            <tr>
                                                <th className="border w-[100%] md:w-[200px] p-4">Hosting Type</th>
                                                <th className="border w-[100%] md:w-[200px] p-4">Introductory Price (per month)</th>
                                                <th className="border w-[100%] md:w-[200px] p-4">Renews At (per month)</th>
                                            </tr>
                                            <tr>
                                                <td className="border w-[100%] md:w-[200px] p-4">Shared</td>
                                                <td className="border w-[100%] md:w-[200px] p-4">$2.99-$3.99</td>
                                                <td className="border w-[100%] md:w-[200px] p-4">$5.99-$11.99</td>
                                            </tr>
                                            <tr>
                                                <td className="border w-[100%] md:w-[200px] p-4">WordPress</td>
                                                <td className="border w-[100%] md:w-[200px] p-4">$2.99-$15.99</td>
                                                <td className="border w-[100%] md:w-[200px] p-4">$5.99-$24.99</td>
                                            </tr>
                                            <tr>
                                                <td className="border w-[100%] md:w-[200px] p-4">Cloud</td>
                                                <td className="border w-[100%] md:w-[200px] p-4">$9.99-$29.99</td>
                                                <td className="border w-[100%] md:w-[200px] p-4">$24.99-$59.99</td>
                                               
                                            </tr>
                                            <tr>
                                                <td className="border w-[100%] md:w-[200px] p-4">VPS</td>
                                                <td className="border w-[100%] md:w-[200px] p-4">$3.99-$117.99</td>
                                                <td className="border w-[100%] md:w-[200px] p-4">$7.99-$186.99</td>
                                            </tr>
                                        </table>


                                    </div>
                                            {data.tdetail}
                                    <div className="cursor-pointer">
                            <Accordian  title="#1. Discounts vs Renewal Prices"
                                detail="We’ve already talked about how Hostinger’s discount prices for new users only last for your first term. So, if you sign up for 12 months, you get one year of discount. After that, you’ll need to pay the renewal price, which will be higher. Our top tip is to always check the renewal price before signing up, to make sure you don’t get hit with an expensive bill once your plan renews!"
                                index="i" />
                                
                                <Accordian  title="#2. Plan Add-Ons: Optional Extras"
                                detail="When you add a plan to your basket, you’ll notice the option to add extras at checkout. These are options you can pick and choose, so you have total control over any extra costs. Add-ons you might see at checkout include: Daily backups: $0.95 per month
                                Cloudflare protection: $9.95 (one-off payment)
                                SEO toolkit: $9.99 per month
                                Priority support: $1.99 per monthsingle shared plan checkout extras hostinger review"
                                index="i" />

                                <Accordian  title="#3. Domain Names: Essential, But Not Necessarily Costly"
                                detail="Custom domain names are vital for any professional website. The good news is, with some of Hostinger’s plans, you actually get a free domain thrown in!

                                cloud hosting checkout free domain hostinger review"
                                index="i" />
                        </div>
                       

                                   <div className="my-5">
                                   <h3 className='font-bold underline py-2'>{data.plantitl}</h3>
                                   <p className='font-bold'>{data.plandetail}</p>
                                   <h3 className='font-bold underline py-2'>{data.additionalcost}</h3>
                                   <p className='font-bold'>{data.additionaldetail}</p>

                                   <img className='w-full md:w-[600px] mx-auto' src={data.additionalimg} alt="" />
                                   <h3 className='font-bold underline py-2'>{data.canceltitle}</h3>
                                   <p className='font-bold'>{data.canceldetail}</p>
                                   </div>
                                   {data.furtherinfo.map(data =>
                                     <ul>
                                     <li className='my-5 text-[1.2em] text-white'><h3 className='text-[1.2em] font-bold'>{data.list}</h3> {data.detail}</li>
                                 </ul>
                                    )}
                    </div>

                    
                )}
            </div>
        </>
    )
}
