import React from 'react'
import FeaturesData from './FeaturesData'

export default function Features() {
  return (
    <>
     <div className=" w-full bg-[#93C5FD] pt-5 pb-7 mb-[5em]">
        {FeaturesData.map(data =>
           <div className="px-5 mx-auto md:w-[80%] pt-5">
                <h3 className='text-[1.4em] md:text-[2.5em] font-bold  mb-5 text-center  text-[#2E3B80]'>{data.title}</h3>
                <p className='text-[1em] my-4 text-center'>{data.detail}</p>

                <div>
                   <table className='mx-auto'>
                        <tr className=''>
                            <th className='border w-[100%] md:w-[200px] p-4'>Hosting Plan</th>
                            <th className='border w-[100%] md:w-[200px] p-4'>Shared</th>
                            <th className='border w-[100%] md:w-[200px] p-4'>VPS</th>
                            <th className='border w-[100%] md:w-[200px] p-4'>Dedicated</th>
                            <th className='border w-[100%] md:w-[200px] p-4'>WordPress</th>
                        </tr>
                            <tr>
                            <td className='border w-[100%] md:w-[200px] p-4'>Linux or Windows</td>
                            <td className='border w-[100%] md:w-[200px] p-4'>Linux</td>
                            <td className='border w-[100%] md:w-[200px] p-4'>Linux / Windows</td>
                            <td className='border w-[100%] md:w-[200px] p-4'>Linux</td>
                            <td className='border w-[100%] md:w-[200px] p-4'>Linux</td>
                            </tr>

                            <tr>
                            <td className='border w-[100%] md:w-[200px] p-4'>SSL</td>
                            <td className='border w-[100%] md:w-[200px] p-4'>SSL Wildcard Certificate</td>
                            <td className='border w-[100%] md:w-[200px] p-4'>SSL Wildcard Certificate</td>
                            <td className='border w-[100%] md:w-[200px] p-4'>SSL Wildcard Certificate</td>
                            <td className='border w-[100%] md:w-[200px] p-4'>SSL Wildcard Certificate</td>
                            </tr>

                            <tr>
                            <td className='border w-[100%] md:w-[200px] p-4'>Storage</td>
                            <td className='border w-[100%] md:w-[200px] p-4'>50-500GB</td>
                            <td className='border w-[100%] md:w-[200px] p-4'>10-240GB</td>
                            <td className='border w-[100%] md:w-[200px] p-4'>2TB HDD / 800GB SSD</td>
                            <td className='border w-[100%] md:w-[200px] p-4'>25-200GB</td>
                            </tr>
                            
                            <tr>
                            <td className='border w-[100%] md:w-[200px] p-4'>Traffic</td>
                            <td className='border w-[100%] md:w-[200px] p-4'>Unlimited traffic</td>
                            <td className='border w-[100%] md:w-[200px] p-4'>Unlimited traffic</td>
                            <td className='border w-[100%] md:w-[200px] p-4'>Unlimited traffic	</td>
                            <td className='border w-[100%] md:w-[200px] p-4'>Unlimited traffic</td>
                            </tr>

                            <tr>
                            <td className='border w-[100%] md:w-[200px] p-4'>Email Accounts</td>
                            <td className='border w-[100%] md:w-[200px] p-4'>1 email account</td>
                            <td className='border w-[100%] md:w-[200px] p-4'>Unlimited mailboxes</td>
                            <td className='border w-[100%] md:w-[200px] p-4'>10,000 email accounts	</td>
                            <td className='border w-[100%] md:w-[200px] p-4'>1-10 email accounts</td>
                            </tr>

                            <tr>
                            <td className='border w-[100%] md:w-[200px] p-4'>Domain</td>
                            <td className='border w-[100%] md:w-[200px] p-4'>1 free domain for 1 year</td>
                            <td className='border w-[100%] md:w-[200px] p-4'>Optional add-on</td>
                            <td className='border w-[100%] md:w-[200px] p-4'>1 free domain	</td>
                            <td className='border w-[100%] md:w-[200px] p-4'>1 free domain for 1 year</td>
                            </tr>
                    </table>
                </div>


            {data.furtherinfo.map(data => 
            <div>    
                <h3 className='text-[1.4em] md:text-[2.5em] font-bold  mb-5 text-center  text-[#2E3B80]'>{data.subtitle}</h3>
                <p className='text-[1em] my-4 text-center'>{data.subdetail}</p>
                </div>
            )}

            </div>
        )}
      </div>
    </>
  )
}
