import React from 'react'
import ReliableData from './ReliableData'

export default function Reliable() {
  return (
    <>
      <div className="my-[5em]">
        {ReliableData.map(data =>            
         <div className="px-5 mx-auto md:w-[80%]">
         
         <div className="">

         <h3 className='text-[1.4em] md:text-[2.5em] font-bold  text-[#2E3B80] text-center'>{data.title}</h3>
         <p className='text-[0.8em] text-center'>{data.label}</p>
         <p className='text-[1em] text-center py-4'>{data.detail}</p>
         <p className='text-[1em] text-center'>{data.subdetail}</p>

          <img src={data.img}  alt="" />
          <h3 className='text-[1.4em] md:text-[2.5em] font-bold  text-[#2E3B80] text-center'>{data.titletwo}</h3>
         </div>
         <h3 className='text-[1.4em] md:text-[2.5em] font-bold  text-[#2E3B80] text-center'>{data.titlethree}</h3>
      </div>
        
        )}
      </div>
    </>
  )
}
