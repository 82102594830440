import img from '../../../assets/images/inmotionpone.webp'
import imgtwo from '../../../assets/images/inmotionptwo.webp'

const PricingData = [
    {
        title:"Pricing & Value for Money",
        detail:"InMotion’s shared hosting is split into three plans: Launch, Power and Pro. But there’s also a special discount plan just for Website Builder Expert readers called Core. ",
        img:img
    },
    {
        detail:"InMotion falls at the value for money hurdle with an overall value score of 2.9 out of 5. Across the board, InMotion is more expensive than its competitors, getting a value score of 2.4 for shared plans, 2 for dedicated, and 3.4 out of 5 for WordPress hosting. Looking at InMotion’s shared plans, Core starts at $3.29/month, though this offer is only available for those who commit to a minimum of a year plan. Power retails at $6.99/month for twelve-month signups and $17.99 thereafter. This plan represents the best value for money, allowing you to host an unlimited amount of websites with 4x faster performance."
    },
    {
        detail:"If you’re unsure, why not just give it a go and see for yourself? InMotion has a 90-day, full money-back guarantee. So if you’re unsatisfied with the service you receive, you can get a full refund any time before the three month mark. WordPress hosting – unsurprisingly, offers better performance for WordPress sites – has four tiers priced between $4.49/month and $16.99/month. VPS hosting starts at $24.99/month (for the year) with both managed and self-managed services. "
    },
    {
        imgtwo:imgtwo
    },
    {
        detail:"Dedicated hosting ranges from $89.99/month to $269.99/month across six plans. The three lower-priced plans have a promotion to double RAM (Random Access Memory), while all but the lowest allow you to choose which of InMotion’s data centers your server is based in. If price is a big sticking point for you, you can get cheaper deals elsewhere. HostGator, for example, starts its shared hosting plans at $2.75/month and offers an excellent service."
    },
    {
        title:"Further Information:",
        detail:"Best VPS Hosting Providers – If you need a VPS hosting provider, look no further."
    },
    {
        title:"InMotion's Place in the Market",
        detail:"In our research on the market, InMotion didn’t come out on top as the poplar host. When we conducted market analysis, InMotion recieved an average 3.3 out of 5 for both dedicated and WordPress hosting, and even lower for its VPS and shared plans. Despite not securing a top spot in the market, InMotion offers a lot of great deals when it comes to its shared and dedicated hosting plans. InMotion is designed for businesses looking to grow, and for personal sites who need great features."
    },
    {
        detail:"InMotion is a Linux-only shop, which means it does not provide Windows-based servers. If you’re after a Windows server, then InMotion does not have what you need. Due to its strong security and performance, however, InMotion is a great choice for photography websites, or online stores looking for scalable features."
    },
    {
        detail:"Best Ecommerce Hosting Providers: InMotion is the second best hosting provider when it comes to ecommerce hosting."
    },
    {
        detail:"InMotion vs Bluehost: See who came out on top when we pitted HostGator against InMotion."
    },
    {
        detail:"InMotion vs SiteGround: Another well-fought contest, but who won?"
    }
]

export default PricingData