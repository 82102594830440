import logo from '../../../assets/icons/hostinger.webp'

const VisitLinkData = [
        {
            logo:logo,
            rating:"3.6",
            
            power:{
                title:"POWERING",
                detail:"3 million sites"
            },

            starting:{
                title:"STARTING FROM",
                detail:"$2.99"
            },
            
            link:"VISIT SITE"
        }
]


export default VisitLinkData