import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button'; // Import the Button component
import CheckCircleIcon from '@mui/icons-material/CheckCircle'; // Import the CheckCircle icon
import './Table.css';

function createData(name, Bluehost, HostGator, InMotion, Dreamhost, GoDaddy, Hostinger, IONOS, Namecheap, SiteGround, icon, data) {
  return { name, Bluehost, HostGator, InMotion, Dreamhost, GoDaddy, Hostinger, IONOS, Namecheap, SiteGround, icon, data };
}

const iconStyle = { color: '#93C5FD' }; // Define the icon color

const buttonStyle = {
  color: '2e3b80',
  backgroundColor: '#93C5FD',
  minWidth: '80px', // Set the minimum width
  minHeight: 'auto', // Set the minimum height
  padding: '0.2em 0.5em', // Adjust padding to make the button smaller
  fontSize: '0.9rem', // Adjust the font size to make the button text smaller
};

const rows = [
  createData('Overall rating', 4.8, 4.7, 4.3, 4.7, 4.7, 4.6, 4.2, 4.0, 4.8),
  createData('Starting from', '$2.95', '$3.75', '$2.49', '$2.59','$11.99', '$2.99', '$1.00', '$1.48', '$2.99'),
  createData('Uptime', '99.98%', '99.99%', '99.99%', '99.98%', '99.99%', '99.99%', '99.99%', '99.99%', '99.99%'),
  createData('Free backups',  ( 
    <React.Fragment >
      <IconButton size="small"><CheckCircleIcon style={iconStyle} /></IconButton>
    </React.Fragment>),
    (
    <React.Fragment>
      <IconButton size="small"><CheckCircleIcon style={iconStyle} /></IconButton>
    </React.Fragment>),
    (
    <React.Fragment>
      <IconButton size="small"><CheckCircleIcon style={iconStyle} /></IconButton>
    </React.Fragment>),
    (
    <React.Fragment>
      <IconButton size="small"><CheckCircleIcon style={iconStyle} /></IconButton>
    </React.Fragment>),
    (
    <React.Fragment>
      <IconButton size="small"><CheckCircleIcon style={iconStyle} /></IconButton>
    </React.Fragment>),
    (
    <React.Fragment>
      <IconButton size="small"><CheckCircleIcon style={iconStyle} /></IconButton>
    </React.Fragment>),
    (
    <React.Fragment>
      <IconButton size="small"><CheckCircleIcon style={iconStyle} /></IconButton>
    </React.Fragment>),
    (
    <React.Fragment>
      <IconButton size="small"><CheckCircleIcon style={iconStyle} /></IconButton>
    </React.Fragment>),
    (
      <React.Fragment>
        <IconButton size="small"><CheckCircleIcon style={iconStyle} /></IconButton>
      </React.Fragment>),
    ''),
  createData('Free domain', (
    <React.Fragment>
      <IconButton size="small"><CheckCircleIcon style={iconStyle} /></IconButton>
    </React.Fragment>),
    (
    <React.Fragment>
      <IconButton size="small"><CheckCircleIcon style={iconStyle} /></IconButton>
    </React.Fragment>),
    (
    <React.Fragment>
      <IconButton size="small"><CheckCircleIcon style={iconStyle} /></IconButton>
    </React.Fragment>),
    (
    <React.Fragment>
      <IconButton size="small"><CheckCircleIcon style={iconStyle} /></IconButton>
    </React.Fragment>),
    (
    <React.Fragment>
      <IconButton size="small"><CheckCircleIcon style={iconStyle} /></IconButton>
    </React.Fragment>),
    (
    <React.Fragment>
      <IconButton size="small"><CheckCircleIcon style={iconStyle} /></IconButton>
    </React.Fragment>),
    (
    <React.Fragment>
      <IconButton size="small"><CheckCircleIcon style={iconStyle} /></IconButton>
    </React.Fragment>),
    (
    <React.Fragment>
      <IconButton size="small"><CheckCircleIcon style={iconStyle} /></IconButton>
    </React.Fragment>),
    (
      <React.Fragment>
        <IconButton size="small"><CheckCircleIcon style={iconStyle} /></IconButton>
      </React.Fragment>),
    ''),
  createData('Storage space', '10GB - Unlimited', '3.5GB - 100GB', '50GB - 100GB', '50GB - Unlimited', '25GB - 100GB', '30GB - 200GB', '25GB - Unlimited', '20GB - 50GB', '10GB - 40GB'),
  createData('Bandwidth', 'Unmetered', 'Unmetered', 'Unlimited', 'Unmetered', 'Unmetered', 'Unlimited', 'Unlimited', 'Unlimited', 'Unmetered'),
  createData('RAM', '512MB – 32GB', '512GB - 16GB', '128GB - 16GB', '30GB - 1GB', '512MB – 32GB', '768MB – 16GB', '512MB – 12GB', '256GB - 2GB', '16GB – 64GB'),
  createData('', (
    <Button href="https://hostinger.sjv.io/6eZ9EN" target="_blank" style={buttonStyle} variant="contained"><svg xmlns="http://www.w3.org/2000/svg" width="12" height="16" id="link"><path fill-rule="evenodd" d="M11 10h1v3c0 .55-.45 1-1 1H1c-.55 0-1-.45-1-1V3c0-.55.45-1 1-1h3v1H1v10h10v-3zM6 2l2.25 2.25L5 7.5 6.5 9l3.25-3.25L12 8V2H6z"></path></svg></Button> ),
    (
    <Button href="https://hostinger.sjv.io/6eZ9EN" target="_blank" style={buttonStyle} variant="contained"><svg xmlns="http://www.w3.org/2000/svg" width="12" height="16" id="link"><path fill-rule="evenodd" d="M11 10h1v3c0 .55-.45 1-1 1H1c-.55 0-1-.45-1-1V3c0-.55.45-1 1-1h3v1H1v10h10v-3zM6 2l2.25 2.25L5 7.5 6.5 9l3.25-3.25L12 8V2H6z"></path></svg></Button> ),
      (
    <Button href="https://hostinger.sjv.io/6eZ9EN" target="_blank" style={buttonStyle} variant="contained"><svg xmlns="http://www.w3.org/2000/svg" width="12" height="16" id="link"><path fill-rule="evenodd" d="M11 10h1v3c0 .55-.45 1-1 1H1c-.55 0-1-.45-1-1V3c0-.55.45-1 1-1h3v1H1v10h10v-3zM6 2l2.25 2.25L5 7.5 6.5 9l3.25-3.25L12 8V2H6z"></path></svg></Button> ),
    (
    <Button href="https://hostinger.sjv.io/6eZ9EN" target="_blank" style={buttonStyle} variant="contained"><svg xmlns="http://www.w3.org/2000/svg" width="12" height="16" id="link"><path fill-rule="evenodd" d="M11 10h1v3c0 .55-.45 1-1 1H1c-.55 0-1-.45-1-1V3c0-.55.45-1 1-1h3v1H1v10h10v-3zM6 2l2.25 2.25L5 7.5 6.5 9l3.25-3.25L12 8V2H6z"></path></svg></Button> ),
    (
      <Button href="https://hostinger.sjv.io/6eZ9EN" target="_blank" style={buttonStyle} variant="contained"><svg xmlns="http://www.w3.org/2000/svg" width="12" height="16" id="link"><path fill-rule="evenodd" d="M11 10h1v3c0 .55-.45 1-1 1H1c-.55 0-1-.45-1-1V3c0-.55.45-1 1-1h3v1H1v10h10v-3zM6 2l2.25 2.25L5 7.5 6.5 9l3.25-3.25L12 8V2H6z"></path></svg></Button> ),
      (
      <Button href="https://hostinger.sjv.io/6eZ9EN" target="_blank" style={buttonStyle} variant="contained"><svg xmlns="http://www.w3.org/2000/svg" width="12" height="16" id="link"><path fill-rule="evenodd" d="M11 10h1v3c0 .55-.45 1-1 1H1c-.55 0-1-.45-1-1V3c0-.55.45-1 1-1h3v1H1v10h10v-3zM6 2l2.25 2.25L5 7.5 6.5 9l3.25-3.25L12 8V2H6z"></path></svg></Button> ),
        (
      <Button href="https://hostinger.sjv.io/6eZ9EN" target="_blank" style={buttonStyle} variant="contained"><svg xmlns="http://www.w3.org/2000/svg" width="12" height="16" id="link"><path fill-rule="evenodd" d="M11 10h1v3c0 .55-.45 1-1 1H1c-.55 0-1-.45-1-1V3c0-.55.45-1 1-1h3v1H1v10h10v-3zM6 2l2.25 2.25L5 7.5 6.5 9l3.25-3.25L12 8V2H6z"></path></svg></Button> ),
      (
      <Button href="https://hostinger.sjv.io/6eZ9EN" target="_blank" style={buttonStyle} variant="contained"><svg xmlns="http://www.w3.org/2000/svg" width="12" height="16" id="link"><path fill-rule="evenodd" d="M11 10h1v3c0 .55-.45 1-1 1H1c-.55 0-1-.45-1-1V3c0-.55.45-1 1-1h3v1H1v10h10v-3zM6 2l2.25 2.25L5 7.5 6.5 9l3.25-3.25L12 8V2H6z"></path></svg></Button> ),
      (
        <Button href="https://hostinger.sjv.io/6eZ9EN" target="_blank" style={buttonStyle} variant="contained"><svg xmlns="http://www.w3.org/2000/svg" width="12" height="16" id="link"><path fill-rule="evenodd" d="M11 10h1v3c0 .55-.45 1-1 1H1c-.55 0-1-.45-1-1V3c0-.55.45-1 1-1h3v1H1v10h10v-3zM6 2l2.25 2.25L5 7.5 6.5 9l3.25-3.25L12 8V2H6z"></path></svg></Button> ),
    
    
    ),
];

export default function BasicTable() {
  return (
    <div className="w-full bg-[#eff6ff] mb-10">
      <div className="w-[100%] md:w-[1100px] m-auto py-[5em]">
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 900 }} aria-label="simple table" className="bg-[#2E3B80]">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell align="center" style={{ color: 'white' }}>Bluehost</TableCell>
                <TableCell align="center" style={{ color: 'white' }}>HostGator</TableCell>
                <TableCell align="center" style={{ color: 'white' }}>InMotion</TableCell>
                <TableCell align="center" style={{ color: 'white' }}>Dreamhost</TableCell>
                <TableCell align="center" style={{ color: 'white' }}>GoDaddy</TableCell>
                <TableCell align="center" style={{ color: 'white' }}>Hostinger</TableCell>
                <TableCell align="center" style={{ color: 'white' }}>IONOS</TableCell>
                <TableCell align="center" style={{ color: 'white' }}>Namecheap</TableCell>
                <TableCell align="center" style={{ color: 'white' }}>SiteGround</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow
                  key={row.name}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row" style={{ color: 'white', padding: '10px', whiteSpace: 'nowrap'}}>
                    {row.name}
                  </TableCell>
                  <TableCell align="right" style={{ color: 'white', padding: '2px', textAlign: 'center', fontFamily: 'inherit'  }}>{row.Bluehost}</TableCell>
                  <TableCell align="right" style={{ color: 'white', padding: '2px', textAlign: 'center', fontFamily: 'inherit'  }}>{row.HostGator}</TableCell>
                  <TableCell align="right" style={{ color: 'white', padding: '2px', textAlign: 'center', fontFamily: 'inherit' }}>{row.InMotion}</TableCell>
                  <TableCell align="right" style={{ color: 'white', padding: '2px', textAlign: 'center', fontFamily: 'inherit'  }}>{row.Dreamhost}</TableCell>
                  <TableCell align="right" style={{ color: 'white', padding: '2px', textAlign: 'center', fontFamily: 'inherit' }}>{row.GoDaddy}</TableCell>
                  <TableCell align="right" style={{ color: 'white', padding: '2px', textAlign: 'center', fontFamily: 'inherit'  }}>{row.Hostinger}</TableCell>
                  <TableCell align="right" style={{ color: 'white', padding: '2px', textAlign: 'center', fontFamily: 'inherit'  }}>{row.IONOS}</TableCell>
                  <TableCell align="right" style={{ color: 'white', padding: '2px', textAlign: 'center', fontFamily: 'inherit' }}>{row.Namecheap}</TableCell>
                  <TableCell align="right" style={{ color: 'white', padding: '2px', textAlign: 'center', fontFamily: 'inherit'  }}>{row.SiteGround}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
}
