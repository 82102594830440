const VisitCardData = [
    {
        id:1,
        number:"#1",
        title: "Bluehost",
        image: require('../../../assets/icons/bluehost.webp'),
        titleTwo:"features",
        subtitle:"Best overall hosting provider",
        rank:"EXCELLENT",
        paid:"Paid Plans: $2.95 - $119.99 / month",
        titlelist:["Best overall hosting provider","Pricing plans from: $2.95 per month","Flash sale! Save up to 70% on selected plans!"],
        detail: "Bluehost takes the top spot as our preferred hosting provider, boasting an impressive average rating of 4.8 out of 5, surpassing its competitors in half of our research categories. But what sets Bluehost apart and contributes to its outstanding score? Let's explore the reasons behind its exceptional performance:",
        mainDataOne:["Consistently affordable", "Strong features" , "Free migration"],
        mainDataTwo:["Excellent WordPress integration", "Responsive support team" , "Best price for dedicated hosting"],
        mainDataThree:["Unreliable site speed and uptime", "Limits on backups for cheaper plans" , "No in-house reseller hosting"],

        link:"https://hostinger.sjv.io/6eZ9EN"
    },
 
    {
        id:2,
        number:"#2",
        title: "IONOS",
        image: require('../../../assets/icons/ionoslogo.webp'),
        titlelist:["Best site speed and uptime","Pricing plans from: $0.50 per month"],
        
        titleTwo:"features",

        detail: "In our comprehensive testing, IONOS emerged as the undisputed leader in both site speed and uptime, significantly outperforming all other hosting providers. Its exceptional performance in these crucial areas underscores the significance of efficiency and reliability in web hosting. The assurance of a stable connection provides users with a sense of peace and confidence in their hosting experience.",
        detailTwo:"While IONOS falls short of the top spot on our overall list for valid reasons, such as limited help and support options and additional charges for certain services like Windows usage, it does offer three affordable shared hosting plans: Starter, Plus, and Ultimate. These plans come with enticing features, including unlimited bandwidth, a remarkable 99.99% uptime guarantee, automatic backups, and a complimentary domain. Despite its drawbacks, IONOS remains a viable option for users seeking budget-friendly hosting solutions with essential features.",

        rank:"EXCELLENT",
        
        paid:"Paid Plans: $0.5 - $35 / month",

        

        subtitle:"Best site speed and uptime",
        
        mainDataOne:["Top site speed + uptime", "Very affordable plans" , "Great dedicated hosting"],
        
        mainDataTwo:["Excellent WordPress integration", "99.99% uptime" , "Best price for dedicated hosting"],
        
        mainDataThree:["Unreliable help and support", "Additional charges to consider" , "More costly for Windows users"],

        link:"https://hostinger.sjv.io/6eZ9EN"
    },

    {
        id:3,
        number:"#3",
        title: "GoDaddy",
        image: require('../../../assets/icons/GoDaddy.png'),
        titlelist:["Speedy server response time","Pricing plans from: $4.99 per month"],
        
        titleTwo:"features",

        detail: "GoDaddy presents four shared hosting plans: Economy, Deluxe, Ultimate, and Maximum. All these plans offer attractive features, including unmetered bandwidth, a guaranteed 99.9% uptime, daily automatic backups, 24/7 network monitoring, and a complimentary domain. While GoDaddy currently holds the sixth position on our table, its site speed performance has been a significant drawback. However, the company excelled in our server response time test, closely trailing behind the leader, IONOS.",
        detailTwo:"Over the years, GoDaddy has made strides in improving its support services. Its 24/7 live chat and phone service are commendable, although email support is not available. If top-notch help and support are essential for your hosting needs, HostGator, the leader in our research, might be a more suitable choice.",

        rank:"EXCELLENT",
        
        paid:"Paid Plans: $6.99 - $469.99 / month",

        

        subtitle:"Speedy server response time",
        
        mainDataOne:["Great features", "Not great value for money" , "Impressive support"],
        
        mainDataTwo:["24/7 phone support", "Free Microsoft 365 email" , "Unmetered bandwidth"],
        
        mainDataThree:["More expensive than competitors", "Restricted migration" , "Favors Linux users over Windows"],
        link:"https://hostinger.sjv.io/6eZ9EN"
    },

    {
        id:4,
        number:"#4",
        title: "Hostinger",
        image: require('../../../assets/icons/Hostinger.png'),
        titlelist:["Good for ecommerce hosting","Pricing plans from: $1.99 per month"],
        
        titleTwo:"features",

        detail: "In the realm of ecommerce hosting, Hostinger proves to be a strong contender alongside SiteGround. While Bluehost clinches the number one spot in our ecommerce research, Hostinger impressively secures third place with an impressive score of 4.5 out of 5. As an affordable and viable alternative to the top provider, it offers an attractive option for users.",
        detailTwo: "Hostinger provides shared, VPS, and WordPress hosting services, though it lacks dedicated hosting plans, potentially limiting scalability for some users. However, its top-tier VPS plan is highly advanced, offering ample room for growth, making it ideal for small to medium-sized websites.",
        detailThree:"Despite boasting servers in seven countries, our testing indicates that Hostinger's site speed and uptime are average, despite its advertised uptime guarantee of 99.99%. Nevertheless, with its competitive offerings, Hostinger remains a compelling choice for ecommerce hosting solutions.",

        rank:"EXCELLENT",
        
        paid:"Paid Plans: $2.99 - $109.99 / month",

        

        subtitle:"Great for ecommerce hosting",
        
        mainDataOne:["Cheap introductory prices", "Good for ecommerce sites" , "Mediocre uptime"],
        
        mainDataTwo:["Data centers on four continents", "Free migration" , "Wide range of VPS plans"],
        
        mainDataThree:["No dedicated plan", "Limited storage space" , "No green hosting"],

        link:"https://hostinger.sjv.io/6eZ9EN"
    },

    {
        id:5,
        number:"#5",
        title: "SiteGround",
        image: require('../../../assets/icons/SiteGround.png'),
        titlelist:["Good option for startup sellers","Pricing plans from: $2.99 per month"],
        
        titleTwo:"features",

        detail: "SiteGround may not exhibit the same level of all-encompassing features as some other hosting providers on this list. However, it excels in WordPress-managed hosting, earning high praise and recommendation from WordPress itself, which is undoubtedly an impressive feat.",
        detailTwo:"In our latest research, SiteGround's features have received a significant boost, scoring an impressive 4.5 out of 5, compared to its previous rating of 4.3. Noteworthy offerings like daily backups, free SSL, and growth-focused tools make it an exceptionally ecommerce-friendly hosting provider, catering to the needs of online businesses and stores.",
        
        rank:"EXCELLENT",
        
        paid:"Paid Plans: $2.99 - $400 / month",

        

        subtitle:"Good option for startup sellers",
        
        mainDataOne:["Great updates and backups", "Good security features" , "Limited types of hosting"],
        
        mainDataTwo:["Free, automated daily backups", "Automatic WordPress updates" , "24/7 live chat support"],
        
        mainDataThree:["No VPS or dedicated plans", "More expensive than competitors" , "Worst server response time"],

        link:"https://hostinger.sjv.io/6eZ9EN"
    },

   
    
]

export default VisitCardData;