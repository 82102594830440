import React from 'react';
import Gallery from 'react-photo-gallery';
import Carousel, { Modal, ModalGateway } from 'react-images';
import photo1 from '../../assets/gallery/photo1.png';
import photo2 from '../../assets/gallery/photo2.png';
import ecommerce1 from '../../assets/gallery/ecommerce1.png';



// Import more photos as needed

const photos = [
  {
    src: photo1,
    width: 473,
    height: 355,
    alt: 'Description for photo 1',
  },
  {
    src: photo2,
    width: 473,
    height: 355,
    alt: 'Description for photo 2',
  },
  {
    src: ecommerce1,
    width: 473,
    height: 355,
    alt: 'ecommerce website design inspiration',
  },
  // Add more photo objects here
];

const columnWidthBreakpoints = [
  { width: 320, columns: 1 },  // Mobile
  { width: 768, columns: 2 },  // Tablet
  { width: 1024, columns: 4 }, // Larger screens
];

function PhotoGallery() {
  const [currentImage, setCurrentImage] = React.useState(0);
  const [viewerIsOpen, setViewerIsOpen] = React.useState(false);

  const openLightbox = (event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  };

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  return (
    <div className="w-full py-10 mb-10">
         <div className="px-5 mx-auto md:w-[80%] pt-5">
    <div>
      <Gallery photos={photos} onClick={openLightbox} columns={calculateColumns()} />
      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Carousel
                currentIndex={currentImage}
                views={photos.map((x) => ({
                  ...x,
                  srcset: x.srcSet,
                  caption: x.alt,
                }))}
              />
              <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'center' }}>
                {photos.map((photo, index) => (
                  <div key={index} style={{ margin: '5px' }}>
                    <img
                      src={photo.src}
                      alt={photo.alt}
                      style={{
                        width: '100px',
                        height: 'auto',
                        cursor: 'pointer',
                        border: currentImage === index ? '2px solid blue' : 'none',
                      }}
                      onClick={() => setCurrentImage(index)}
                    />
                  </div>
                ))}
              </div>
            </div>
          </Modal>
        ) : null}
      </ModalGateway>
    </div>
    </div>
    </div>
    
  );
}


function calculateColumns() {
  const width = window.innerWidth;
  if (width < 768) return 3; // Mobile
  if (width < 1024) return 4; // Tablet
  return 5; // Larger screens
}

export default PhotoGallery;
