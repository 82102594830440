import React from 'react';
import CustomerData from './CustomerData';

export default function Customer() {
  return (
    <div className="w-full py-8">
      <div className="container mx-auto px-4 md:px-8">
        {CustomerData.map((data, index) => (
          <div key={index} className="mb-8">
            <div className="px-5 py-6 bg-white rounded-lg shadow-lg">
              {data.title && (
                <h3 className="text-2xl md:text-3xl font-bold text-blue-800 mb-4 text-center">
                  {data.title}
                </h3>
              )}
              {data.detail && (
                <p className="text-lg md:text-xl text-gray-700 mb-4 text-center">{data.detail}</p>
              )}
              {data.detailItalic && (
                <p className="text-lg md:text-xl text-gray-700 italic mb-4 text-center">
                  {data.detailItalic}
                </p>
              )}
              {data.date && (
                <p className="text-lg md:text-xl text-gray-700 mb-4 text-center">{data.date}</p>
              )}
              {data.img && (
                <div className="flex justify-center mb-4">
                  <img
                    src={data.img}
                    className="w-full md:w-2/3 h-auto rounded-lg shadow-sm"
                    alt="Customer testimonial"
                  />
                </div>
              )}
              <div>
                {data.summary?.smalltext && (
                  <span className="block text-sm text-gray-600 mb-2 text-center">
                    {data.summary.smalltext}
                  </span>
                )}
                {data.summary?.detailmore && (
                  <p className="text-lg md:text-xl text-gray-700 mb-4 text-center">
                    {data.summary.detailmore}
                  </p>
                )}
                {data.detaillast && (
                  <p className="text-lg md:text-xl text-gray-700 mb-4 text-center">{data.detaillast}</p>
                )}
              </div>
              {data.limitations?.limitationstitle && (
                <div className="mt-6">
                  <h3 className="text-2xl md:text-3xl font-bold text-blue-800 mb-4 text-center">
                    {data.limitations.limitationstitle}
                  </h3>
                  <p className="text-lg md:text-xl text-gray-700 text-center">
                    {data.limitations.limitationdetail}
                  </p>
                </div>
              )}
              {data.contactInfo?.contact && (
                <div className="mt-6">
                  <p className="text-lg md:text-xl text-gray-700 font-bold mb-4 text-center">
                    For assistance, call <strong>888-401-4678</strong> or use the{' '}
                    <strong>Bluehost support chat</strong> for prompt help. You can also contact{' '}
                    <strong>Bluehost customer support</strong> for more information.
                  </p>
                  <p className="text-lg md:text-xl text-gray-700 text-center whitespace-pre-line">
                    Visit us at Bluehost Headquarters, 5335 Gate Pkwy, 2nd Floor, Jacksonville, FL 32256. For detailed support and inquiries, explore our{' '}
                    <strong>Bluehost customer care</strong> services or check our{' '}
                    <strong>Bluehost.com customer service</strong> options online.
                  </p>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
