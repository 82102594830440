import React from 'react'

export default function Banner(props) {
    return (
        <>

            <div className="main text-center py-[6em] bg-[#2E3B80]">
                <div className="title">
                    <h3 className="text-[2em] md:text-[4.5em] capitalize font-bold text-white">{props.title} <span className='text-blue-300'>{props.subtitle}</span></h3>
                </div>
                <div className="detail pt-5">
                    <p className='text-[1.2em] md:text-[1.2em] text-white'>{props.detail}</p>
                </div>
            </div>

        </>
    )
}
