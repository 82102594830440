import React from 'react'
import OverAllData from './OverAllData'



export default function OverAll() {


  return (
    <>
      <div className="mx-4 mb-[5em]">
        {OverAllData.map(data =>
          <div className="mx-auto md:w-[80%]">
            <div className="md:flex justify-between  items-center border-b pb-5">
              <div className="">
                <h3 className='text-[1.4em] font-bold'>{data.title}</h3>
                <p className='text-[1em] my-4'>{data.subtitle}</p>
                <div className="img border w-full md:w-[250px] p-2">
                  <img src={data.logo} alt="" className='h-[140px] w-full md:w-[160px] text-center mx-auto' />
                </div>
              </div>
              <div className="md:ml-8 mt-4 md:mt-0">
                <h3 className='text-[1.4em] md:text-[2em] font-bold'>{data.overall.title}</h3>
                <p className='text-[1em] md:text-[1.2em] '>{data.overall.detail}</p>
              </div>
            </div>
            <div className="mt-5">
              <h3 className='text-[1.4em] md:text-[2.5em] font-bold text-center'>{data.webhosting.title}</h3>
              <p className='text-[1em] md:text-[1.2em]'>{data.webhosting.detail}</p>
              <img src={data.webhosting.img} className='w-full py-5' alt="" />
              <p className='text-[1em] md:text-[1.2em] '>{data.webhosting.subdetail}</p>

              <h3 className='text-[1.4em] md:text-[2.5em] font-bold text-left'>{data.bluehostfor.title}</h3>
              <p className='text-[1em] md:text-[1.2em]'>{data.bluehostfor.detail}</p>
            </div>
           
          </div>
      )}
    
</div>


    </>
  )
}
