const FeaturesData = [
    {
        title:"Hosting Features",
        detail:"When it comes to features, IONOS is a mixed bag; its shared hosting and dedicated hosting plans are fairly strong, while its VPS and WordPress hosting plans offer little in the way of features. But across all hosting types, IONOS score averages out to 4.5 out of 5 for features, ranking in joint first with GreenGeeks.Below you can see some of the main features – including storage – for the shared, VPS, dedicated, and WordPress hosting plans:",
  

      furtherinfo:[
        {
            subtitle:"Shared Hosting Features Breakdown",
             subdetail:"In our testing of shared hosting providers, IONOS scores 4.6 out of 5 for features, and it’s not hard to see why. All plans come equipped with daily backups, a free SSL certificate, unlimited traffic, 24/7 support, a professional email, and a free domain. Of the three shared hosting plans, the Essential plan – designed for one website – is a little limited, only allowing one website and 10GB of storage. But once you upgrade to the Business and Expert plans, IONOS includes unlimited websites, storage, and databases, too. For the cheap price of IONOS’ shared hosting plans, the features included are pretty impressive. However, IONOS doesn’t have the industry standard cPanel dashboard, using its own clunky alternative instead. Since many people start out with shared hosting, this can make the whole experience harder for those who need it to be the easiest. We’ve covered this in more detail in the ease of use section. Overall, IONOS’ shared hosting plans are cheap and a good starting point for websites. Storage, traffic allowances, and power (CPU cores) are pretty impressive (especially for the price), but you’ll need to opt for the Business plan or above to enjoy the features fully."
         },
         {
            subtitle:"VPS Hosting Features Breakdown",
            subdetail:"Our main takeaway from the VPS hosting plans is that they’re just a bit… weak. In our testing, IONOS scores a poor 2.5 out of 5 for VPS hosting features. It has, on average, fewer CPU cores (power) and less RAM (memory) than competitors, so you may find your site to be slower than if it were hosted with a different provider. For example, Bluehost’s cheapest VPS plan comes with 2 cores, 2GB of RAM, and 30GB of storage. A lot, right? In comparison, IONOS’ cheapest VPS plan offers 1 core, 512MB of RAM, and 10GB of storage. Again, IONOS advertises cheap plans, but, in this case, that’s the tradeoff. For strong VPS hosting features, we recommend you take a look at HostGator or InMotion instead."
        },
        {
            subtitle:"Dedicated Hosting Features Breakdown",
            subdetail:"Thankfully, the offering of features picks up again with IONOS’ dedicated hosting plans. IONOS is our second best provider for dedicated hosting, scoring 4.7 out of 5 overall, and an equally strong rating of 4.3 out of 5 for its features. There’s plenty of power on offer; if your website is getting serious traffic, it’s in very safe hands. There are four HDD (hard disk drive) and four SSD (solid state drive) plans to choose from, with the cheapest offering a whopping 8GB of RAM, 8 cores, and 240GB of storage. Of course, greater power means a bigger dent in your budget, but IONOS’ plans remain fairly reasonable. Dedicated hosting is the priciest hosting type of all, no matter which hosting provider you choose."
        },
        {
            subtitle:"WordPress Hosting Features Breakdown ",
            subdetail:"Unfortunately, the features on IONOS’ WordPress hosting plans are pretty poor, only scoring 2.7 out of 5 in our testing – one of the lowest feature ratings out of all the providers. All plans come optimized for WordPress, with daily backups, 100% SSD storage, unlimited traffic, a free domain, and customizable updates. But this isn’t that impressive since most WordPress hosting providers offer these basic features. You’ll need to upgrade to the highest plan, Boost, to enjoy more power, including 75GB of storage, 20GB of RAM, and 10 email addresses. With IONOS being the king of site speed, the majority of its marketing highlights the stability and fast-loading times of its hosting plans, so your WordPress site can run effectively. Plus, there’s a website migration tool in the works to make things as easy as possible! Realistically though, if you’re looking at WordPress hosting, we’d recommend a hosting provider that’s actually recommended by WordPress itself, like Bluehost."
        },
        {
            subtitle:"Further Information",
            subdetail:"Web Hosting Comparison Chart: Compare the best hosting providers and their key features in our comparison chart."
        },

    ]
},
]


 

export default FeaturesData