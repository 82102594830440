import img from '../../../assets/images/hostingeruptime.webp'

const ReliableData = [
    {
        title:"Reliability & Performances",
        
        detail:"Hostinger has its strengths but it falls short when it comes to reliability and performance.",
        subdetail:"Despite an advertised uptime of 99.9%, our research found Hostinger’s site speed and uptime to be pretty mediocre in reality. In fact, it only scores 3.5 out of 5 for its uptime performance – an average score when compared to the likes of IONOS which scored a perfect 5 out of 5.",

    },
    {
    
    detail:"No web host can guarantee 100% uptime, though – unpredictable events such as power outages can throw a spanner in the works! That being said, you’ll want to aim for something in the 99.97-99.99% range, which equates to between 50 and 150 minutes of downtime per year.",
    img:img,
    titletwo:"Why Does Uptime Matter?",
    },
    {
        detail:"As you weigh up Hostinger’s strengths and weaknesses, you might be wondering how much a less-than-stellar uptime will matter for your specific site. Uptime is especially important if you’re hosting an ecommerce site, because the more downtime you experience, the less sales you’ll be able to make. Good uptime matters across the board, but we say it should be a make-or-break factor for ecommerce sites. If website performance sounds critical to you, we recommend you check out IONOS instead since it has the best uptime and site speed out of all the hosting providers we’ve reviewed.",
    },
    {
        titlethree:"Need more ecommerce advice?",
    },
    {
        detail:"Free Ecommerce Website Templates: Give your online store an attractive headstart on the competition with this selection of free designs.",
    },
    {
        detail:"Best Ecommerce Software: We help you pick the right software for your business."
    }
]


export default ReliableData