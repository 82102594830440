import React from 'react'
import Banner from '../../home/banner/Banner'
export default function Header() {
  return (
    <>
       <div className=''>
      <h3>
        <Banner title="HostGator" subtitle="Review" detail="Is It the Right Provider for You?" />
      </h3>
      </div>
    </>
  )
}
