import React from 'react'
import Banner from '../../home/banner/Banner'
export default function Header() {
  return (
    <>
       <div>
      <h3>
        <Banner title="InMotion" subtitle="Review" detail="Feature-Rich and Business-Focused" />
      </h3>
      </div>
    </>
  )
}
