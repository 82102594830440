import React from 'react'
import PricingData from './PricingData'
import Accordian from '../../bluehost/accordian/Accordian'

export default function Pricing() {
  return (
    <>
         <div className=" w-full bg-[#93C5FD] pt-5 pb-7 mb-[5em]">
        {PricingData.map(data =>
        <div className="px-5 mx-auto md:w-[80%] pt-5">
        <div className="">
        <h3 className='text-[1.4em] md:text-[2.5em] font-bold  border-[#2E3B80]  text-[#2E3B80]'>{data.title}</h3>
        </div>

        <div className="">
            <p className='my-5 text-[1.2em] text-white'>{data.detail}</p>
            <img src={data.img} alt="" className='mx-auto my-4' />
            <p className='text-center'>{data.subdetail}</p>
        </div>

       

          

        </div>
        )}
 <div className="">
                        <Accordian title="Why do we show 12-month plans"
                         detail="It’s important to know that the longer you commit to your hosting plan, the cheaper it will be. For example, paying three years’ worth of hosting fees upfront works out cheaper than paying monthly for the year. Think of it as a loyalty discount.
                         Many hosting providers like DreamHost show their three-year plans to customers so they see the cheapest price first. But we think three years is a bit of a commitment to make to a hosting provider! So we tend to show the annual prices, as we think they are a better reflection of the plan’s value and a more sensible contract length to you, the users." 
                          
                          
                               index="i"/>
                      </div>
<div className="">
                <table className="mx-auto mt-5">
                    <tr>
                        <th className='p-5 border md:w-[280px]'>Hosting Type</th>
                        <th className='p-5 border md:w-[280px]'>Discount Price Range</th>
                        <th className='p-5 border md:w-[280px]'>Renewal Price Range</th>
                    </tr>
                    <tr>
                        <td className='p-5 border md:w-[280px] text-center'>Shared</td>
                        <td className='p-5 border md:w-[280px] text-center'>$2.95 per month</td>
                        <td className='p-5 border md:w-[280px] text-center'>$6.99-$12.99 per month</td>
                    </tr>

                    <tr>
                        <td className='p-5 border md:w-[280px] text-center'>Managed WordPress</td>
                        <td className='p-5 border md:w-[280px] text-center'>$16.95-$71.95 per month</td>
                        <td className='p-5 border md:w-[280px] text-center'>$19.99-$74.99 per month</td>
                    </tr>

                    <tr>
                        <td className='p-5 border md:w-[280px] text-center'>VPS</td>
                        <td className='p-5 border md:w-[280px] text-center'>$10-$80 per month</td>
                        <td className='p-5 border md:w-[280px] text-center'>$12.99-$92.99 per month</td>
                    </tr>

                    <tr>
                        <td className='p-5 border md:w-[280px] text-center'>Dedicated</td>
                        <td className='p-5 border md:w-[280px] text-center'>NA</td>
                        <td className='p-5 border md:w-[280px] text-center'>$149-$379 per month</td>
                    </tr>
                </table>
            </div>
            {PricingData.map(data =>
            <div className="px-5 mx-auto md:w-[80%] pt-5">
            <div className="">
            <h3 className='text-[1.4em] md:text-[2.5em] font-bold  border-[#2E3B80]  text-[#2E3B80] text-center'>{data.subtitle}</h3>
               <p className='my-5 text-[1.2em] text-white text-center'>{data.subdetail}</p>
            </div>
            </div>
            )}

<div className="">
                <table className="mx-auto mt-5">
                    <tr>
                        <th className='p-5 border md:w-[280px]'>Provider</th>
                        <th className='p-5 border md:w-[280px]'>Shared Hosting</th>
                        <th className='p-5 border md:w-[280px]'>WordPress Managed Hosting</th>
                        <th className='p-5 border md:w-[280px]'>VPS Hosting</th>
                        <th className='p-5 border md:w-[280px]'>Dedicated Hosting</th>
                    </tr>
                    <tr>
                        <td className='p-5 border md:w-[280px] text-center'>DreamHost</td>
                        <td className='p-5 border md:w-[280px] text-center'>$2.95</td>
                        <td className='p-5 border md:w-[280px] text-center'>$16.95-$71.95</td>
                        <td className='p-5 border md:w-[280px] text-center'>$10-$80</td>
                        <td className='p-5 border md:w-[280px] text-center'>$149-$379</td>
                    </tr>

                    <tr>
                        <td className='p-5 border md:w-[280px] text-center'>Bluehost</td>
                        <td className='p-5 border md:w-[280px] text-center'>$2.95-$13.95</td>
                        <td className='p-5 border md:w-[280px] text-center'>$24.95-$51.95</td>
                        <td className='p-5 border md:w-[280px] text-center'>$24.99-$83.99</td>
                        <td className='p-5 border md:w-[280px] text-center'>$99.99-$139.99</td>
                    </tr>

                    <tr>
                        <td className='p-5 border md:w-[280px] text-center'>HostGator</td>
                        <td className='p-5 border md:w-[280px] text-center'>$3.95-$7.25</td>
                        <td className='p-5 border md:w-[280px] text-center'>$7.95-$13.95</td>
                        <td className='p-5 border md:w-[280px] text-center'>$23.95-$59.95</td>
                        <td className='p-5 border md:w-[280px] text-center'>$109.97-$159.98</td>
                    </tr>

                    <tr>
                        <td className='p-5 border md:w-[280px] text-center'>InMotion</td>
                        <td className='p-5 border md:w-[280px] text-center'>$3.49-$14.99</td>
                        <td className='p-5 border md:w-[280px] text-center'>$4.49-$16.99 (Shared WordPress)</td>
                        <td className='p-5 border md:w-[280px] text-center'>$24.99-$59.99</td>
                        <td className='p-5 border md:w-[280px] text-center'>$69.99-$259.99</td>
                    </tr>

                    <tr>
                        <td className='p-5 border md:w-[280px] text-center'>A2 Hosting</td>
                        <td className='p-5 border md:w-[280px] text-center'>$2.99-$14.99</td>
                        <td className='p-5 border md:w-[280px] text-center'>$17.99-$47.99</td>
                        <td className='p-5 border md:w-[280px] text-center'>$6.59-$86.99</td>
                        <td className='p-5 border md:w-[280px] text-center'>$109.99-$219.99</td>
                    </tr>
                </table>
            </div>
      </div>
    </>
  )
}
