import img from '../../../assets/images/Bluehost_uptime_reliable.png';

const ReliableData = [
  {
    img: img,
    label: "DreamHost steals the top spot with a mammoth 100% uptime, but HostGator is still in the running! HostGator offers a pretty solid 99.90% uptime guarantee, this means that your site will experience 8h 41m 38s of downtime per year. If you’re looking for reliability, HostGator is the cream of the crop – this kind of consistency is good for any site, but if you’re looking to set up an ecommerce store it might be quite the loss. Time is money, after all!",
    detail: "On shared plans there’s also DreamHost that beats HostGator’s uptime with an astounding 100% uptime. And on VPS plans Liquid Web which also has a staggering 100% uptime. Otherwise HostGator stands firmly in the middle with its uptime guarantee.",
    subdetail: "What HostGator’s uptime means is that if your shared or reseller server is down for longer than nine hours per year, you’ll get a month of credit for the plan you’ve chosen.' Further Information :-' DreamHost vs HostGator – check out these two providers head to head. Best Ecommerce Hosting Providers – Near perfect uptime is one of the reasons why HostGator ranks so well, check out the other ecommerce hosting providers to compare."
  }
];

export default ReliableData;
